import Subsection from "./SidebarNavSection"
import vectorBack from "images/icons/VectorBack.svg"
import { Button, IconButton, List } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useBackCourseRedirection } from "entities/Test/model/backCourseRedirection"
import { useSelector, useDispatch } from "react-redux"
import { timerActions } from "shared/store"
import { useTheme } from "../../shared/context"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import React from "react"

const BaseContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.sidebar,
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
}))

const Subsections = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}))

const SidebarNav = ({ subsections, setCurrent, courseSlug, hasAdaptiveTest, showSidebar, setShowSidebar }) => {
  const theme = useTheme()
  const isRunningTimerTest = useSelector(state => state.timer.isRunning)
  const { redirect } = useBackCourseRedirection(courseSlug)
  const dispatch = useDispatch()

  const handleBack = () => {
    if (isRunningTimerTest) {
      return dispatch(timerActions.setIsExitModal(true))
    }
    redirect()
  }

  return (
    <BaseContainer>
      <List disablePadding>
        <Subsections>
          {subsections &&
            subsections.map(subsection => (
              <Subsection
                hasAdaptiveTest={hasAdaptiveTest}
                component="div"
                key={subsection.title}
                pages={subsection.subsections}
                title={subsection.title}
                currentPrefix={{ section: subsection.index }}
                setCurrent={setCurrent}
              />
            ))}
        </Subsections>
      </List>
      <div
        style={{
          width: "98%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleBack}
          variant={"contained"}
          sx={{
            marginLeft: "8%",
            marginRight: "8%",
            backgroundColor: "#1565C0",
            width: "60%",
            marginBlock: "4vh",
            [theme.breakpoints.down("sm")]: {
              width: "70%",
              marginLeft: "2%",
              marginRight: "2%",
            },
          }}
        >
          <img style={{ marginRight: "6%", height: "12px", width: "14px" }} src={vectorBack} />
          <div
            style={{
              fontStyle: "normal",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0.3",
            }}
          >
            В список модулей
          </div>
        </Button>
        <IconButton onClick={() => setShowSidebar(false)} style={{ padding: 0, width: "36px", height: "36px" }}>
          <ArrowBackIosNewIcon
            style={{
              rotate: !showSidebar && "90deg",
              color: "white",
              backgroundColor: "#1565C0",
              fontSize: 26,
              height: "100%",
              width: "100%",
              borderRadius: "6px",
            }}
          />
        </IconButton>
      </div>
    </BaseContainer>
  )
}

export default SidebarNav
