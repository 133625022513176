import React, { useEffect, useState } from "react"
import { PolicyText } from "shared/assets"
import * as Styled from "./ui/Registration.styled"
import { CircularProgress, Typography } from "@mui/material"
import { FirsStepButtonsContainer, SendButtonDiv } from "./ui/Registration.styled"
import { Button } from "shared/ui"
import { axiosInstance as axios } from "shared/api/axiosInstance"
//@ts-ignore
import Cookies from "js-cookie"
import { FormProvider, useForm } from "react-hook-form"
import { FormInput } from "./ui/FormInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { ErrorsParser, schema } from "entities/User/index"
import { FormPhoneInput } from "./ui/FormPhoneInput"
import { IFormData } from "entities/User"
import { useTheme, useUserState } from "../../shared/context"

export const RegistrationFirstStep = ({
  setStep,
  setRegisterHash,
  setUserData,
  data,
}: {
  data: IFormData | null
  setStep: (value: number) => void
  setRegisterHash: (value: string) => void
  setUserData: (value: string) => void
}) => {
  const { login, registration } = useUserState()
  const methods = useForm({ resolver: yupResolver(schema) })
  const {
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = methods
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(([fieldName, fieldValue]) => {
        //@ts-ignore
        setValue(fieldName, fieldValue)
      })
    }
  }, [data, setValue])

  function registerUser(data: IFormData) {
    setIsLoading(true)
    axios.get("/register/email/").then(() => {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios
        .post("/register/email/", {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
        })
        .then(res => {
          setRegisterHash(res.data.key)
          //@ts-ignore
          setUserData(data)
          setIsLoading(false)
          setStep(2)
        })
        .catch(err => {
          if (err.response.status === 302) {
            setError("email", {
              type: "server",
              message: "Данный адрес электронной почты уже зарегистрирован.",
            })
          }
          if (err.response && err.response.data) {
            const apiErrors = err.response.data
            Object.keys(apiErrors).forEach(field => {
              //@ts-ignore
              setError(field, { type: "server", message: ErrorsParser[apiErrors[field]] })
            })
          }
          setIsLoading(false)
        })
    })
  }

  const handleClickLogin = () => {
    registration.setOpen(false)
    login.setOpen(true)
  }

  return (
    <Styled.FirstStepMainContainer>
      <FormProvider {...methods}>
        <form style={{ display: "flex", flexDirection: "column", gap: 16, width: "100%" }}>
          <FormInput required={true} label={"Фамилия"} name={"last_name"} />
          <FormInput required={true} label={"Имя"} name={"first_name"} />
          <FormInput required={false} label={"Отчество"} name={"middle_name"} />
          <FormPhoneInput required={true} label={"Номер телефона"} name={"phone"} />
          <FormInput type={"email"} required={true} label={"Почта"} name={"email"} />
          <FormInput required={true} label={"Пароль"} name={"password"} type={"password"} />
          <FormInput required={true} label={"Подтвердите пароль"} name={"passwordConfirm"} type={"password"} />
        </form>
      </FormProvider>
      <PolicyText text={"ПОЛУЧИТЬ КОД"} />
      <FirsStepButtonsContainer>
        <SendButtonDiv>
          {/*@ts-ignore*/}
          <Button
            sx={{ [theme.breakpoints.down("sm")]: { width: "100%" } }}
            color={"primary"}
            variant={"contained"}
            onClick={handleSubmit(registerUser)}
          >
            ПОЛУЧИТЬ КОД
          </Button>
          <Typography variant={"caption"} fontWeight={"small"}>
            Код будет отправлен на указанную почту
          </Typography>
          {isLoading && <CircularProgress sx={{ height: 48, width: 48 }} size={"small"} />}
        </SendButtonDiv>
        {/*@ts-ignore*/}
        <Button onClick={handleClickLogin}>Вход</Button>
      </FirsStepButtonsContainer>
    </Styled.FirstStepMainContainer>
  )
}
