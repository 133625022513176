// @ts-ignore
import styled from "@emotion/styled"
import { useContext, useState } from "react"
import RequestsScatterChart from "./RequestsScatterChart"
import RequestsAndStatusesPieChart from "./RequestsAndStatusesPieChart"
import RequestsAndStatusesBarChart from "./RequestsAndStatusesBarChart"
import { RangesType, StatisticsVariants } from "../../model/StatisticsContext.types"
import { DateRange } from "features/Organization/ui/EventDateSelector"
import { ChartsContext } from "features/Organization/SummaryStatistics"

export type RequestsAndStatusesChartType = "scatter" | "pie" | "bar"

interface IProps {
  type: StatisticsVariants
  rangeType?: RangesType
  statisticsDisplayedDate?: DateRange
}

export const RequestsAndStatusesCharts = ({ rangeType, type, statisticsDisplayedDate }: IProps) => {
  const [RequestsAndStatusesChartTypeTitle, setRequestsAndStatusesChartTypeTitle] =
    useState<RequestsAndStatusesChartType>("scatter")
  const chartsContext = useContext(ChartsContext)
  const changeChartType = (title: RequestsAndStatusesChartType) => {
    setRequestsAndStatusesChartTypeTitle(title)
    chartsContext?.setChartsContextValue(title)
  }

  return (
    <PlotWrapper>
      {RequestsAndStatusesChartTypeTitle === "scatter" && type === "requests" && (
        <RequestsScatterChart
          changeChartType={changeChartType}
          rangeType={rangeType}
          type={"requests"}
          statisticsDisplayedDate={statisticsDisplayedDate}
        />
      )}
      {RequestsAndStatusesChartTypeTitle === "scatter" && type === "statuses" && (
        <RequestsAndStatusesPieChart changeChartType={changeChartType} rangeType={rangeType} type={type} />
      )}
      {RequestsAndStatusesChartTypeTitle === "pie" && rangeType === "all" && (
        <RequestsAndStatusesPieChart changeChartType={changeChartType} rangeType={rangeType} type={type} />
      )}
      {RequestsAndStatusesChartTypeTitle === "pie" && rangeType !== "all" && (
        <RequestsScatterChart
          changeChartType={changeChartType}
          rangeType={rangeType}
          type={"requests"}
          statisticsDisplayedDate={statisticsDisplayedDate}
        />
      )}
      {RequestsAndStatusesChartTypeTitle === "bar" && rangeType === "all" && (
        <RequestsAndStatusesBarChart changeChartType={changeChartType} rangeType={rangeType} type={type} />
      )}
      {RequestsAndStatusesChartTypeTitle === "bar" && rangeType !== "all" && (
        <RequestsScatterChart
          changeChartType={changeChartType}
          rangeType={rangeType}
          type={"requests"}
          statisticsDisplayedDate={statisticsDisplayedDate}
        />
      )}
    </PlotWrapper>
    //
  )
}
const PlotWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 60%;
  flex: 60%;
  padding: 0 32px 32px;

  & > div:first-child {
    flex: 1;

    & > div {
      height: 0;
    }
  }
`
