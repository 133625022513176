import React, { useState, useEffect } from "react"
import { Box, Breadcrumbs, Button, Link, List, ListItem, Menu, MenuItem, Typography } from "@mui/material"
import { useTheme } from "shared/context"
import { useUserState } from "shared/context"
//@ts-ignore
import { useHistory } from "react-router-dom"
import { Notifications } from "features"
import { timerActions } from "shared/store"
// @ts-ignore
import Logo from "images/logo-eqvium.png"
import * as Styled from "./ui/Header.Styled"
import { LoginAndRegistrationContainer } from "./ui/LoginAndRegistrationContainer"
import { routes } from "./util/Routes"
import { UserMenu } from "./ui/UserMenu"
import { Drawer } from "./ui/Drawer"
import { useWindowSizeChange } from "shared/hooks"
import { fetchProfile } from "entities/User"
import { fetchParticipantStatus } from "entities/Participant/api/participant"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { useAppDispatch } from "../../shared/hooks/useAppDispatch"
import config from "../../shared/config"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"

interface IProps {
  buttonText?: string
  logo?: string
  variant: "landing" | "default" | "shadow"
  slug?: string
  catalogLink?: string | null
  headerItems?: {
    label: string
    ref: React.Ref<any>
  }[]
}

export function Header({ catalogLink = null, variant, logo, slug, buttonText = "КАТАЛОГ", headerItems }: IProps) {
  const theme = useTheme()
  const history = useHistory()
  const { isSmall } = useWindowSizeChange()
  const { isAuthenticated } = useUserState()
  const [profile, setProfile] = useState(null)
  const [isStaff, setIsStaff] = useState(null)
  const [isOrganizationsModerator, setIsOrganizationsModerator] = useState(false)
  const isRunningTimerTest = useAppSelector(state => state.timer.isRunning)
  const user_id = localStorage.getItem("user")
  const status = localStorage.getItem("user_status")
  const dispatch = useAppDispatch()
  const [isOpenPageMenu, setIsOpenPageMenu] = useState<React.MouseEvent<HTMLButtonElement> | null>(null)
  const [organization, setOrganization] = useState<{
    id: number
    logo: string
    icon_logo: string
    template_props: string
  } | null>(null)
  const [isOrganizationPage, setIsOrganizationPage] = useState<undefined | boolean>(undefined)

  useEffect(() => {
    const windowUrl = window.location.origin
    if (!windowUrl.split("//")[1].includes(config.hostApi.split("//")[1])) {
      axios.get("organizations", { params: { domain: windowUrl.split("//")[1] } }).then(res => {
        setOrganization(res.data[0])
        setIsOrganizationPage(true)
      })
    } else {
      setIsOrganizationPage(false)
    }
  }, [])

  useEffect(() => {
    if (user_id) {
      fetchProfile().then(res => {
        setProfile(res.data)
        localStorage.setItem("user_status", res.data.status)
        axios.get("/organizations/is_organizations_moderator").then(res => {
          setIsOrganizationsModerator(res.data.is_organizations_moderator)
        })
      })
      fetchParticipantStatus().then(res => {
        setIsStaff(res.data.is_staff)
      })
    }
  }, [user_id])

  const redirect = (link: string) => {
    const isPreventing = preventLeaving(link)
    if (isPreventing) return
    history.push(link)
  }

  const redirectOnLogo = () => {
    if (variant === "landing") return
    if (!!organization) window.location.href = "/"
    if (!slug) window.location.href = "/"
    else window.location.href = `/organization/${slug}`
  }

  const preventLeaving = (link: string) => {
    if (isRunningTimerTest) {
      dispatch(timerActions.setIsExitModal(true))
      link && dispatch(timerActions.setExitLink(link))
    }
    return isRunningTimerTest
  }

  function isActive(field: string) {
    if (history.location.pathname.slice(1).includes(field)) return "#DEE7F2"
    else return null
  }

  function goToMainPage(event: React.MouseEvent<HTMLButtonElement>) {
    if (window.location.pathname === "/") {
      setIsOpenPageMenu(event?.currentTarget)
    } else {
      redirect("/")
    }
  }

  function onCloseMenu() {
    setIsOpenPageMenu(null)
  }

  if (isSmall === null) return <></>

  if (isOrganizationPage === undefined) return <></>

  return (
    <Styled.LandingAppBar
      sx={{
        boxShadow: variant === "landing" ? "none" : "0 2px 4px rgb(0 0 0 / 5%)",
        position: variant === "default" ? "absolute" : "fixed",
      }}
    >
      <Styled.LandingToolbar>
        <Styled.LandingLogoContainer>
          {/*@ts-ignore*/}
          <Styled.LandingLogo
            isOrganizationLogo={
              !!logo || !window.location.origin.split("//")[1].includes(config.hostApi.split("//")[1])
            }
            src={
              window.location.origin.split("//")[1].includes(config.hostApi.split("//")[1]) && !logo
                ? Logo
                : organization?.logo || logo
            }
            sx={variant !== "landing" ? { cursor: "pointer" } : {}}
            onClick={redirectOnLogo}
          />
        </Styled.LandingLogoContainer>
        {isSmall && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 4,
              width: "30%",
            }}
          >
            {isAuthenticated && <Notifications color="#000000" />}
            <Drawer
              hasOrganizations={isOrganizationsModerator}
              catalogLink={
                !!catalogLink
                  ? catalogLink
                  : !!organization
                  ? `/catalog/organization/${organization.id}`
                  : routes.catalog
              }
              buttonText={!!organization ? JSON.parse(organization?.template_props)?.header_text : buttonText}
              isActive={isActive}
              profile={profile}
              status={status as string}
              isStaff={isStaff}
            />
          </div>
        )}
        {!isSmall && (
          <>
            <Styled.NavigationContainer>
              <Button
                onClick={goToMainPage}
                aria-owns={Boolean(isOpenPageMenu) ? "menu-appbar" : undefined}
                aria-haspopup="true"
              >
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: window.location.pathname === "/" ? theme.palette.primary.main : "#000",
                  }}
                >
                  ГЛАВНАЯ
                </div>
              </Button>
              {/*@ts-ignore*/}
              {!!headerItems && (
                <Menu
                  anchorEl={isOpenPageMenu} // Привязка к HTML-элементу
                  open={Boolean(isOpenPageMenu)}
                  onClose={onCloseMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <List sx={{ minWidth: "100px" }}>
                    {headerItems.map(e => (
                      <ListItem
                        onClick={() => {
                          e.ref.current.scrollIntoView()
                          onCloseMenu()
                        }}
                        sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#DEE7F2" } }}
                      >
                        {e.label}
                      </ListItem>
                    ))}
                  </List>
                </Menu>
              )}
              <Button
                component={Link}
                onClick={() =>
                  redirect(
                    !!catalogLink
                      ? catalogLink
                      : !!organization
                      ? `/catalog/organization/${organization.id}`
                      : routes.catalog
                  )
                }
              >
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "16px",
                    color: "#000",
                  }}
                >
                  {!!organization ? JSON.parse(organization?.template_props)?.header_text : buttonText}
                </div>
              </Button>
            </Styled.NavigationContainer>
            {isAuthenticated && (
              <Styled.AuthUserContainer>
                <Notifications color="#000000" />
                <UserMenu
                  hasOrganizations={isOrganizationsModerator}
                  isActive={isActive}
                  profile={profile}
                  status={status as string}
                  isStaff={isStaff}
                />
              </Styled.AuthUserContainer>
            )}
            <LoginAndRegistrationContainer />
          </>
        )}
      </Styled.LandingToolbar>
    </Styled.LandingAppBar>
  )
}
