import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const CarouselMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  height: "650px",
  marginTop: theme.spacing(20),
  gap: theme.spacing(4),
  justifyContent: "space-between",
}))

export const NextCarouselItem = styled("div")(({ theme }) => ({
  height: "600px",
  width: theme.spacing(6),
  cursor: "pointer",
  borderBottomLeftRadius: "24px",
  borderTopLeftRadius: "24px",
  [theme.breakpoints.down("lg")]: {},
}))

export const PrevCarouselItem = styled("div")(({ theme }) => ({
  height: "600px",
  width: theme.spacing(6),
  borderBottomRightRadius: "24px",
  borderTopRightRadius: "24px",
  cursor: "pointer",
  [theme.breakpoints.down("lg")]: {},
}))

export const LandingBackground = styled("div")(({ theme }) => ({
  height: "600px",
  borderRadius: theme.spacing(6),
}))

export const LandingContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(32),
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(40),
    paddingRight: theme.spacing(40),
  },
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(16),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(8),
  },
}))

export const LandingImage = styled(Box)(({ theme }) => ({
  "& span": {
    display: "flex !important",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "100%",
    },
  },
  [theme.breakpoints.up("xl")]: {
    width: "85vh",
  },
  [theme.breakpoints.up("lg")]: {
    width: "85vh",
  },
  [theme.breakpoints.down("lg")]: {
    overflow: "hidden",
    objectFit: "cover",
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {
    height: "auto",
  },
}))

export const SloganContainer = styled("div")(({ theme }) => ({
  width: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: theme.spacing(8),
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(4),
  },
}))
