import React, { useState } from "react"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material"

export const PolicyText = ({ text }: { text: string }) => {
  const [isOpenPolicy, setIsOpenPolicy] = useState(false)

  return (
    <>
      <Typography style={{ color: "black", fontSize: 14, width: "100%" }}>
        Нажимая на кнопку "{text}", я даю своё согласие на обработку персональных данных в соответствии с
        <a
          href="http://www.consultant.ru/document/cons_doc_LAW_61801/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1565C0", textDecoration: "none", cursor: "pointer" }}
        >
          {" "}
          Федеральным законом "О персональных данных" от 27.07.2006 №152-ФЗ
        </a>{" "}
        и соглашаюсь с
        <a
          href={'/policy'}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1565C0", textDecoration: "none", cursor: "pointer" }}
        >
          {" "}
          Политикой конфиденциальности
        </a>{" "}
        и
        <a
          href={'/terms'}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1565C0", textDecoration: "none", cursor: "pointer" }}
        >
          {" "}
          Пользовательским соглашением
        </a>
        .
      </Typography>
      <Dialog
        aria-labelledby="DialogTitle"
        aria-describedby="DialogDescription"
        open={isOpenPolicy}
        onClose={() => setIsOpenPolicy(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent style={{ height: 600 }}>
          <DialogContentText id="DialogDescription" style={{ height: "100%" }}>
            <iframe
              src="https://syncwoia.com/static/media/extra/policy.html"
              width={900}
              height={"100%"}
              style={{ border: "none" }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenPolicy(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
