import { styled } from "@mui/material/styles"
import theme from "tailwindcss/defaultTheme"

export const BackgroundContainer = styled('div')(({theme}) => ({
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  width: "100%",
  backgroundColor: "#F9F9F9",
  marginTop: theme.spacing(18),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}))

export const TermsTextContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(13),
  maxWidth: theme.spacing(256),
  margin: "0 auto",
  width: '100%',
  color: "#313439",
}))

export const DocumentTitle = styled("h1")(({theme}) => ({
  textAlign: "center",
  fontWeight: "bold",
  fontSize: theme.spacing(4.5),
  marginBottom: theme.spacing(5),
}))

export const ParagraphTitle = styled("h2")(({theme}) => ({
  fontWeight: "bold",
  fontSize: theme.spacing(4.5),
  margin: `${theme.spacing(5)} 0 ${theme.spacing(2.5)}`,
  textAlign: "center",
}))

export const Subsection = styled("div")(({theme }) => ({
  marginBottom: theme.spacing(4),
}))

export const Paragraph = styled("p")(({ theme }) => ({
  fontSize: theme.spacing(4.5),
  textIndent: "2em",
  hyphens: "auto",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  textAlign: "justify",
}))

export const ImgPayingSystems = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(10),
  marginTop: theme.spacing(10),
  width: theme.spacing(95),
  height: "auto",
  [theme.breakpoints.down("xs")]: {
    width: "60%",
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  }
}));
