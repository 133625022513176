import { styled } from "@mui/material/styles"
import { Grid, Typography } from "@mui/material"

export const TeamOfOrgMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  width: "100%",
  gap: theme.spacing(2),
  paddingTop: theme.spacing(16),
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  paddingBottom: theme.spacing(16),
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(40),
    paddingRight: theme.spacing(40),
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(40),
    paddingRight: theme.spacing(40),
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const DescriptionDivContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(12),
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  borderRadius: theme.spacing(3),
  width: "30%",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(4),
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginBottom: 0,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    marginBottom: 0,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(6),
  },
}))

export const StyledText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(4),
  },
}))

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    fontSize: theme.spacing(5),
  },
}))
