import React, { useState } from "react"
import { styled } from "@mui/material/styles"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  e: {
    backgroundColor: string
    options: { logo: string; logo_negative: string; href: string }[]
  }
}

export const AllPartners = ({ e, handleChangeRef }: IProps) => {
  return (
    <MainContainer sx={{ backgroundColor: e.backgroundColor }}>
      {e.options.map(item => (
        <PartnerItem e={item} />
      ))}
    </MainContainer>
  )
}

const PartnerItem = ({ e }: { e: { logo: string; logo_negative: string; href: string } }) => {
  const [hover, setHover] = useState(false)

  return (
    <img
      src={hover ? e.logo_negative : e.logo}
      onClick={() => window.open(e.href, "_blank")}
      style={{ maxHeight: "128px", cursor: "pointer" }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    />
  )
}

const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(12),
  flexWrap: "wrap",
  padding: `${theme.spacing(29)} ${theme.spacing(24)}`,
}))
