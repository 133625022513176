import { makeStyles } from "@mui/styles"
import { padding } from "@mui/system"

export default makeStyles(theme => ({
  submissionFeedback: {
    background: "#DEF3FC",
    border: "1px solid #81D9FF",
    borderRadius: "6px",
    padding: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1280,
    boxSizing: "border-box",
    marginTop: 58,
    marginBottom: 48,
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      padding: theme.spacing(5),
      flexDirection: "column",
    },
  },

  feedbackText: {
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "155%",
    fontStyle: "normal",
    fontFamily: "Roboto",
    color: "#313439",
    "& b": {
      fontWeight: 500,
    },
  },
  feedbackTextMess: {
    fontWeight: 300,
    fontSize: "18px",
    lineHeight: "155%",
    fontStyle: "normal",
    fontFamily: "Roboto",
    color: "#313439",
    marginTop: 22,
  },
  filename: {
    color: "#313439",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: 18,
    lineHeight: "155%",
  },
}))
