import { IParticipantsResponse } from "./types"
// @ts-ignore
import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

export const getOrganizationParticipants = async (
  organizationId: number,
  params?: Record<string, string | number | boolean | undefined>
): Promise<IParticipantsResponse> =>
  await axios.get(`organizations/${organizationId}/courses/`, { params }).then(res => res.data)

export const downloadOrganizationParticipants = async (
  organizationId: number,
  params?: Record<string, string | number | boolean | undefined>
) => await axios.get(`organizations/${organizationId}/courses/`, { params, responseType: "arraybuffer" })

export const getOrganizationEventStatistics = async (organizationId: number | undefined, params: Record<string, any>) =>
  await axios.get(`organizations/${organizationId}/statistics/`, { params }).then(res => res.data)

export const downloadOrganizationEventStatistics = async (organizationId: number, params: any) =>
  await axios.get(`organizations/${organizationId}/statistics/`, { params, responseType: "arraybuffer" })

export const getOrganizationRequestsAndStatusesEventStatistics = async (
  organizationId: number | undefined,
  params: Record<string, any>
) => await axios.get(`organizations/${organizationId}/statistics/`, { params }).then(res => res.data)

export const getRequestsAndStatusesChart = async (organizationId: number | undefined, params: Record<string, any>) =>
  await axios.get(`organizations/${organizationId}/statistics/`, { params }).then(res => res.data)
