import React from "react"
import { DocumentTitle, ParagraphTitle, Paragraph, BackgroundContainer, PolicyTextContainer } from "./ui/policy.styles"
import { Header, Footer } from "widgets"

const PolicyPage = () => {
  const items1 = [
    "Установление и поддержание связи между Пользователем или Клиентом и ООО «Битроникс», консультирование по вопросам" +
    " оказания услуг;",
    "Информирование Пользователей и Клиентов о Предложениях ООО «Битроникс»;",
    "Продажа Пользователям и Клиентам товаров (выполнение работ, оказание услуг) и заключение с ними иных подобных " +
    "договоров, а также исполнение таких договоров;",
    "Сбор информации о действиях Пользователей на Веб-Сайте для улучшения качества обслуживания, изменения содержания " +
    "Веб-Сайта.",
  ]

  const items2 = [
    "ФИО;",
    "Электронный адрес (E-mail);",
    "Номер телефона;",
    "Название организации;",
    "Город (Населенный пункт);",
    "Логин для мессенджера «Telegram»;",
    "Класс обучения (если применимо).",
  ]

  const items3 = [
    "Пользователи;",
    "Клиенты.",
  ]

  const items4 = [
    "Федеральный закон от 27.07.2006. №152–ФЗ «О персональных данных»;",
    "Постановление Правительства РФ от 1 ноября 2012 г. № 1119 «Об утверждении требований " +
    "к защите персональных данных при их обработке в информационных системах персональных данных»;",
    "Положения ГК РФ;",
    "Гл. 14 и иные нормы ТК РФ;",
    "Ст. 18 и иные нормы Федерального закона от 13.03.2006 № 38-ФЗ «О рекламе»;",
    "Федеральный закон «Об информации, информационных технологиях и о защите информации» от 27.07.2006 № 149-ФЗ;",
    "Иные законы и подзаконные акты, регулирующие порядок обработки персональных данных;",
    "Устав ООО «Битроникс»;",
    "Локальные нормативные акты ООО «Битроникс» в сфере обработки персональных данных;",
    "Согласие субъекта персональных данных на обработку персональных данных."
  ]

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header variant={"shadow"}/>
      <BackgroundContainer>
        <PolicyTextContainer>
          <DocumentTitle>
            <span>Политика в отношении обработки персональных данных</span>
          </DocumentTitle>
          <ParagraphTitle>
            I. Общие положения
          </ParagraphTitle>
          <Paragraph>
            Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона
            от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по
            обеспечению безопасности персональных данных ООО «Битроникс» (далее – Оператор).
            <br />
            <br />
            1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод
            человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность
            частной жизни, личную и семейную тайну.
            <br />
            <br />
            2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко
            всей информации, которую Оператор может получить о посетителях веб-сайта{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://syncwoia.com"
              >
                https://syncwoia.com
              </a>
            </span>, а также о других субъектах персональных данных.
          </Paragraph>
          <ParagraphTitle>
            II. Основные понятия, используемые в Политике
          </ParagraphTitle>
          <Paragraph>
            1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств
            вычислительной техники;
            <br />
            <br />
            2. Блокирование персональных данных – временное прекращение обработки персональных данных
            (за исключением случаев, если обработка необходима для уточнения персональных данных);
            <br />
            <br />
            3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных,
            обеспечивающих их доступность в сети интернет по сетевому адресу{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://syncwoia.com"
              >
                https://syncwoia.com
              </a>
            </span>;
            <br />
            <br />
            4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных,
            и обеспечивающих их обработку информационных технологий и технических средств;
            <br />
            <br />
            5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования
            дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту
            персональных данных;
            <br />
            <br />
            6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых
            с использованием средств автоматизации или без использования таких средств с персональными данными,
            включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных;
            <br />
            <br />
            7. Оператор – ООО «Битроникс», осуществляющее обработку персональных данных, а также определяющее цели
            обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции),
            совершаемые с персональными данными;
            <br />
            <br />
            8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому
            Пользователю Веб- Сайта{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://syncwoia.com"
              >
                https://syncwoia.com
              </a>
            </span>
            {" "}или иным физическим лицам (субъектам персональных данных);
            <br />
            <br />
            9. Пользователь – любой посетитель веб-сайта{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://syncwoia.com"
              >
                https://syncwoia.com
              </a>
            </span>;
            10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному
            лицу или определенному кругу лиц;
            <br />
            <br />
            11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных
            неопределенному кругу лиц;
            <br />
            <br />
            12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного
            государства органу власти иностранного государства, иностранному физическому или иностранному юридическому
            лицу;
            <br />
            <br />
            13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются
            безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной
            системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных
            данных;
            <br />
            <br />
            14. Предложения – данные о продаваемых товарах, оказываемых услугах, проводимых и спонсируемых мероприятиях и
            иной деятельности ООО «Битроникс»;
            <br />
            <br />
            15. Клиенты - лица, которым ООО «Битроникс» продает товары/оказывает услуги
            (для которых выполняет работы, оказывает услуги) или совершает в их пользу иные действия по
            гражданско-правовым договорам;
            <br />
            <br />
            16. Файлы «cookie» - данные, автоматически передаваемые Оператору в процессе использования Веб-Сайта
            с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес,
            географическое местоположение, информация о браузере и виде операционной системы устройства Пользователя,
            технические характеристики оборудования и программного обеспечения, используемых Пользователем,
            дата и время доступа к Веб-Сайту;
            <br />
            <br />
            Принимая условия настоящей Политики, Пользователь соглашается с использованием фалов «cookie».
          </Paragraph>
          <ParagraphTitle>
            Цели обработки персональных данных
          </ParagraphTitle>
          <Paragraph>
            1. Обработка персональных данных осуществляется ООО «Битроникс» в следующих целях:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items1.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            IV. Установление и поддержание связи между Пользователем или Клиентом и ООО «Битроникс»,
            консультирование по вопросам продажи товаров (выполнения работ, оказания услуг)
          </ParagraphTitle>
          <ParagraphTitle>
            1. Категории и перечень Персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются следующие категории персональных данных:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items2.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            2. Категории субъектов персональных данных:
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются персональные данные следующих категорий лиц:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items3.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            3. Перечень действий с персональными данными.
          </ParagraphTitle>
          <Paragraph>
            С персональными данными, обрабатываемыми для достижения указанной цели, могут производиться следующие
            действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение, иные действия, в случае если необходимость их совершения предусмотрена законом, либо если их
            совершение не противоречит закону, а также настоящей Политике, локальным нормативным актам Оператора и
            условиям согласия субъекта персональных данных на их обработку.
          </Paragraph>
          <ParagraphTitle>
            4. Способы обработки персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Персональные данные, обрабатываемые для достижения указанной цели, обрабатываются смешанным способом.
          </Paragraph>
          <ParagraphTitle>
            V. Обработка персональных данных Пользователей и Клиентов для целей информирования о Предложениях
            ООО «Битроникс»
          </ParagraphTitle>
          <Paragraph>
            Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях
            и различных событиях, при условии выражения им согласия на получение указанных Предложений.
            <br />
            <br />
            Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на
            адрес электронной почты support@syncwoia.com с пометкой «Отказ от уведомлениях о новых продуктах и услугах
            и специальных предложениях».
          </Paragraph>
          <ParagraphTitle>
            1. Категории и перечень Персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются следующие категории персональных данных:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items2.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            2. Категории субъектов персональных данных:
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются персональные данные следующих категорий лиц:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items3.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            3. Перечень действий с персональными данными.
          </ParagraphTitle>
          <Paragraph>
            С персональными данными, обрабатываемыми для достижения указанной цели, могут производиться следующие
            действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение, иные действия, в случае если необходимость их совершения предусмотрена законом, либо если их
            совершение не противоречит закону, а также настоящей Политике, локальным нормативным актам Оператора и
            условиям согласия субъекта персональных данных на их обработку.
          </Paragraph>
          <ParagraphTitle>
            4. Способы обработки персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Персональные данные, обрабатываемые для достижения указанной цели, обрабатываются смешанным способом.
          </Paragraph>
          <ParagraphTitle>
            VI. Обработка персональных данных Пользователей и Клиентов для целей продажи им товаров
            (выполнения работ, оказания услуг) и заключения с ними иных подобных договоров
          </ParagraphTitle>
          <ParagraphTitle>
            1. Категории и перечень Персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются следующие категории персональных данных:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items2.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            2. Категории субъектов персональных данных:
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются персональные данные следующих категорий лиц:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items3.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            3. Перечень действий с персональными данными.
          </ParagraphTitle>
          <Paragraph>
            С персональными данными, обрабатываемыми для достижения указанной цели, могут производиться следующие
            действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение, иные действия, в случае если необходимость их совершения предусмотрена законом, либо если их
            совершение не противоречит закону, а также настоящей Политике, локальным нормативным актам Оператора и
            условиям согласия субъекта персональных данных на их обработку.
          </Paragraph>
          <ParagraphTitle>
            4. Способы обработки персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Персональные данные, обрабатываемые для достижения указанной цели, обрабатываются смешанным способом.
          </Paragraph>
          <ParagraphTitle>
            VII. Обработка персональных данных в целях сбора информации о действиях Пользователей на Веб-Сайте для
            улучшения качества обслуживания, изменения содержания Веб-Сайта
          </ParagraphTitle>
          <Paragraph>
            На Веб-Сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью
            сервисов интернет-статистики (Яндекс Метрика, Гугл Аналитика и других).
            <br />
            <br />
            Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера
            Пользователя (включено сохранение файлов «cookie» и использованы технологии JavaScript).
            <br />
            <br />
            Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора
            информации о действиях Пользователей на Веб-Сайте, улучшения качества Веб-Сайта и его содержания.
          </Paragraph>
          <ParagraphTitle>
            1. Категории и перечень Персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются следующие категории персональных данных:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                <li style={{ marginBottom: "8px" }}>
                  - файлы «cookie».
                </li>
            </ul>
          </Paragraph>
          <ParagraphTitle>
            2. Категории субъектов персональных данных:
          </ParagraphTitle>
          <Paragraph>
            Для достижения указанной цели, ООО «Битроникс» обрабатываются персональные данные следующих категорий лиц:
            <br />
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                <li style={{ marginBottom: "8px" }}>
                  - Пользователи
                </li>
            </ul>
          </Paragraph>
          <ParagraphTitle>
            3. Перечень действий с персональными данными.
          </ParagraphTitle>
          <Paragraph>
            С персональными данными, обрабатываемыми для достижения указанной цели, могут производиться следующие
            действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
            уничтожение, иные действия, в случае если необходимость их совершения предусмотрена законом, либо если их
            совершение не противоречит закону, а также настоящей Политике, локальным нормативным актам Оператора и
            условиям согласия субъекта персональных данных на их обработку.
          </Paragraph>
          <ParagraphTitle>
            4. Способы обработки персональных данных.
          </ParagraphTitle>
          <Paragraph>
            Персональные данные, обрабатываемые для достижения указанной цели, обрабатываются смешанным способом.
          </Paragraph>
          <ParagraphTitle>
            VIII. Правовые основания обработки персональных данных
          </ParagraphTitle>
          <Paragraph>
            К правовым основаниям обработки персональных данных относятся:
            <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
              {items4.map((item, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  - {item}
                </li>
              ))}
            </ul>
          </Paragraph>
          <ParagraphTitle>
            IX. Порядок и сроки сбора, хранения, передачи, уничтожения и других видов обработки персональных данных
          </ParagraphTitle>
          <Paragraph>
            Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых,
            организационных и технических мер, необходимых для выполнения в полном объеме требований действующего
            законодательства в области защиты персональных данных.
            <br />
            <br />
            1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ
            к персональным данным неуполномоченных лиц.
            <br />
            <br />
            2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам,
            за исключением случаев, связанных с исполнением действующего законодательства.
            <br />
            <br />
            3. В случае выявления неточностей в персональных данных, Пользователь или иной субъект персональных данных
            может актуализировать их самостоятельно, путем направления Оператору уведомления на адрес электронной почты
            Оператора support@syncwoia.com с пометкой «Актуализация персональных данных».
            <br />
            <br />
            4. Для всех целей обработки персональных данных, предусмотренных гл. III настоящей Политики, срок обработки
            персональных данных является неограниченным (персональные данные хранятся до тех пор, пока это отвечает
            целям их обработки), если предельный срок хранения не предусмотрен законом.
            <br />
            <br />
            Пользователь или иной субъект персональных данных может в любой момент отозвать свое согласие на обработку
            персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес
            Оператора support@syncwoia.com с пометкой «Отзыв согласия на обработку персональных данных».
            <br />
            <br />
            Персональные данные хранятся и обрабатываются до момента отпадения цели их обработки, либо наступления
            обстоятельств, предусмотренных законом, либо до принятия Оператором решения об их уничтожении (удалении),
            либо до момента отзыва Пользователем или иным субъектом персональных данных согласия на их обработку.
            <br />
            <br />
            5. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки
            Пользователем самостоятельно через специальные формы, расположенные на сайте https://syncwoia.com
            или выражения им согласия на обработку файлов «cookie». Оператор обрабатывает данные иных субъектов
            персональных данных в случаях и в порядке, предусмотренном законом, настоящей Политикой, локальными
            нормативными актами Оператора.
            <br />
            <br />
            Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору (в т.ч. путем нажатия
            кнопки «Отправить»), Пользователь выражает свое согласие с данной Политикой и дает согласие на обработку
            персональных данных.
            <br />
            <br />
            6. Для всех целей обработки персональных данных, предусмотренных гл. III настоящей Политики, уничтожение
            (удаление) персональных данных осуществляется в случаях и в сроки, предусмотренные законом или локальным
            нормативным актом Оператора. Если законом не установлен срок уничтожения персональных данных, срок уничтожения
            определяется Оператором самостоятельно.
            <br />
            <br />
            Уничтожение персональных данных на бумажных носителях осуществляется следующим способом: разрезание,
            гидрообработка, сжигание, механическое уничтожение или др.
            <br />
            <br />
            Уничтожение персональных данных на электронных носителях осуществляется следующим способом: автоматическое или
            осуществляемое вручную удаление данных в базе данных их нахождения, стирание на устройстве гарантированного
            уничтожения информации, физическое уничтожение микросхем диска или др.
            <br />
            <br />
            Уничтожение персональных данных оформляется приказом Оператора или актом об уничтожении персональных данных.
            <br />
            <br />
            Оператор уведомляет субъекта персональных данных об уничтожении его персональных данных в случае, если это
            предусмотрено законом.
          </Paragraph>
          <ParagraphTitle>
            X. Трансграничная передача персональных данных
          </ParagraphTitle>
          <Paragraph>
            1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том,
            что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных,
            обеспечивается надежная защита прав субъектов персональных данных.
            <br />
            <br />
            2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих
            вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта
            персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной
            которого является субъект персональных данных.
          </Paragraph>
          <ParagraphTitle>
            XI. Заключительные положения
          </ParagraphTitle>
          <Paragraph>
            1. Ни одно положение настоящей Политики не может трактоваться как ограничивающее права субъектов
            персональных данных, либо возлагающее на оператора персональных данных не предусмотренные законодательством
            РФ полномочия и обязанности. В случае возникновения противоречия между положениями настоящей Политики и
            законодательства, безусловному применению подлежат нормы закона.
            <br />
            <br />
            2. Пользователь или иной субъект персональных данных может получить любые разъяснения по интересующим
            вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной
            почты support@syncwoia.com.
            <br />
            <br />
            3. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором.
            Политика действует бессрочно до замены ее новой версией.
            <br />
            <br />
            4. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу{" "}
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://syncwoia.com"
              >
                https://syncwoia.com
              </a>
            </span>
          </Paragraph>
        </PolicyTextContainer>
      </BackgroundContainer>
      <Footer />
    </div>
  )
}

export default PolicyPage