import * as Styled from "./Legend.styles"
import SummaryTemplateTab from "../styles/SummaryTemplateTab"
import { useContext, useEffect, useState } from "react"
import { Autocomplete } from "shared/ui"
import { Size } from "shared/ui/styles.const"
import { ILegendItem, RangesType, StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { LegendCard } from "./LegendCard"
import { useAppSelector } from "../../../../shared/hooks/useAppSelector"
import { IEvent } from "../../model/Organization.types"
import dayjs from "dayjs"
import { DateRange } from "../../../../features/Organization/ui/EventDateSelector"
import { ChartsContext } from "../../../../features/Organization/SummaryStatistics"
import { useAppDispatch } from "shared/hooks/useAppDispatch"
import { courseActions } from "shared/store"
// @ts-ignore
import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

export interface ITabOption {
  title: string
  value: number
  enable?: boolean
  hidden?: boolean
}

interface IProps {
  type: StatisticsVariants
  infoType: { id: number; value: string; label: string }
  setInfoType: (value: { id: number; value: string; label: string }) => void
  tabOptions: ITabOption[]
  infoOptions: { id: number; value: string; label: string }[]
  isLoading?: boolean
  event?: IEvent
  rangeType?: RangesType | undefined
  statisticsDisplayedDate?: DateRange | undefined
}

const RequestsAndStatusesLegend = ({
  type,
  infoType,
  setInfoType,
  tabOptions,
  infoOptions,
  event,
  rangeType,
  statisticsDisplayedDate,
}: IProps) => {
  const dispatch = useAppDispatch()

  const chartsContext = useContext(ChartsContext)
  const isScatterChart = chartsContext?.chartsContextValue === "scatter"
  const initActiveTab = tabOptions.find(option => !option?.hidden)
  const [tab, setTab] = useState(initActiveTab?.value || 0)
  const currentOrganizationId = useAppSelector(state => state.organization.currentOrganization?.id)
  const currentCourse = useAppSelector(state => state.course.course)
  const changeTab = useAppSelector(state => state.organization.changeTab)
  const handleChange = (_: any, value: any) => {
    setInfoType(value)
  }
  const [requestsLegend, setRequestsLegend] = useState<ILegendItem[]>([
    { color: "#0873A1", data: 0, title: "Входящие заявки", type: "total" },
    { color: "#2EAD58", data: 0, title: "Принятые" },
    { color: "#FF564E", data: 0, title: "Отклонённые" },
    { color: "#6B6D70", data: 0, title: "Необработанные" },
  ])
  const [statusesLegend, setStatusesLegend] = useState<ILegendItem[]>([
    { color: "#1B7CEA", data: 0, title: "Всего пользователей", type: "total" },
    { color: "#CA44E0", data: 0, title: "Модераторы" },
    { color: "#25C6D0", data: 0, title: "Преподаватели" },
    { color: "#3EC344", data: 0, title: "Активные участники" },
    { color: "#7B85AB", data: 0, title: "Другие пользователи" },
  ])
  const legendBackgroundColor = type === "requests" ? "requestsBg" : "statusesBg"
  useEffect(() => {
    let formattedStartDate
    let formattedFinishDate
    if (
      statisticsDisplayedDate &&
      statisticsDisplayedDate.start !== "Invalid Date" &&
      statisticsDisplayedDate.finish !== "Invalid Date"
    ) {
      formattedStartDate = dayjs(statisticsDisplayedDate.start, "DD.MM.YYYY").format("YYYY-MM-DD")
      formattedFinishDate = dayjs(statisticsDisplayedDate.finish, "DD.MM.YYYY").format("YYYY-MM-DD")
    }

    const config =
      rangeType === "custom" && formattedStartDate && formattedFinishDate
        ? {
            params: {
              course: currentCourse?.id,
              type: "applications_chart",
              date_range: rangeType,
              start: formattedStartDate,
              finish: formattedFinishDate,
            },
          }
        : {
            params: {
              course: currentCourse?.id,
              type: type === "requests" ? "applications_chart" : "statuses_chart",
              date_range: rangeType,
            },
          }
    axios.get(`organizations/${currentOrganizationId}/statistics/`, config).then(res => {
      if (type === "requests") {
        setRequestsLegend([
          { color: "#0873A1", data: res.data.total, title: "Входящие заявки", type: "total" },
          { color: isScatterChart ? "#00000066" : "#2EAD58", data: res.data.approved, title: "Принятые" },
          { color: isScatterChart ? "#00000066" : "#FF564E", data: res.data.rejected, title: "Отклонённые" },
          { color: isScatterChart ? "#00000066" : "#6B6D70", data: res.data.requested, title: "Необработанные" },
        ])
      } else {
        setStatusesLegend([
          { color: "#1B7CEA", data: res.data.total, title: "Всего пользователей", type: "total" },
          { color: "#CA44E0", data: res.data.moderators, title: "Модераторы" },
          { color: "#25C6D0", data: res.data.teachers, title: "Преподаватели" },
          { color: "#3EC344", data: res.data.active, title: "Активные участники" },
          { color: "#7B85AB", data: res.data.others, title: "Другие пользователи" },
        ])
      }
    })
  }, [rangeType, currentCourse, type, statisticsDisplayedDate, isScatterChart])

  const changeTabHandler = () => {
    changeTab("organizations_control_tab", 1)
    dispatch(courseActions.setParticipantsAndRequestsCourse(currentCourse))
  }

  // @ts-ignore
  return (
    <>
      {type === "requests" ? (
        <Styled.LegendWrapper legendBackgroundColor={legendBackgroundColor}>
          <SummaryTemplateTab tab={tab} changeTab={(e, newTab) => setTab(newTab)} tabOptions={tabOptions} isLegend />
          {tab === 0 && (
            <Styled.DataContainer>
              <Styled.RequestsTotalContainer>
                <Styled.RequestsTotalCount>{requestsLegend[0].data}</Styled.RequestsTotalCount>
                <Styled.RequestsTotalLabel>{requestsLegend[0].title}</Styled.RequestsTotalLabel>
              </Styled.RequestsTotalContainer>

              {rangeType === "all" ? (
                <Styled.InProgressFailedContainer>
                  <Styled.InProgressContainer color={chartsContext?.chartsContextValue}>
                    <Styled.RequestsTotalCount>{requestsLegend[1].data}</Styled.RequestsTotalCount>
                    <Styled.RequestsTotalLabel>{requestsLegend[1].title}</Styled.RequestsTotalLabel>
                  </Styled.InProgressContainer>
                  <Styled.FailedContainer color={chartsContext?.chartsContextValue}>
                    <Styled.RequestsTotalCount>{requestsLegend[2].data}</Styled.RequestsTotalCount>
                    <Styled.RequestsTotalLabel>{requestsLegend[2].title}</Styled.RequestsTotalLabel>
                  </Styled.FailedContainer>
                </Styled.InProgressFailedContainer>
              ) : null}
              {rangeType === "all" ? (
                <Styled.RequestedContainer color={chartsContext?.chartsContextValue}>
                  <Styled.RequestedCount>{requestsLegend[3].data}</Styled.RequestedCount>
                  <Styled.RequestedLabel>{requestsLegend[3].title}</Styled.RequestedLabel>
                </Styled.RequestedContainer>
              ) : null}

              <Styled.HandleRequests onClick={changeTabHandler}>Обработать заявки</Styled.HandleRequests>
            </Styled.DataContainer>
          )}

          {tab === 1 && (
            <Styled.LegendInnerWrapper>
              <Styled.SelectorWrapper>
                <Autocomplete
                  label="Тип информации"
                  options={infoOptions}
                  size={Size.small}
                  value={infoType.label}
                  onChange={handleChange}
                  disabled={type === "requests"}
                />
              </Styled.SelectorWrapper>
              <Styled.InfoItemsWrapper>
                <Styled.InfoItems>
                  <LegendCard
                    label={event?.title}
                    legend={rangeType === "all" ? requestsLegend : [requestsLegend[0]]}
                  />
                </Styled.InfoItems>
              </Styled.InfoItemsWrapper>
            </Styled.LegendInnerWrapper>
          )}
        </Styled.LegendWrapper>
      ) : (
        <Styled.LegendWrapper legendBackgroundColor={legendBackgroundColor}>
          <SummaryTemplateTab tab={tab} changeTab={(e, newTab) => setTab(newTab)} tabOptions={tabOptions} isLegend />
          {tab === 0 && (
            <Styled.DataContainer>
              <Styled.TotalUsersContainer>
                <Styled.TotalUsersCount>{statusesLegend[0].data}</Styled.TotalUsersCount>
                <Styled.TotalUsersLabel>{statusesLegend[0].title}</Styled.TotalUsersLabel>
              </Styled.TotalUsersContainer>

              {rangeType === "all" ? (
                <>
                  <Styled.ModeratorsTeachersContainer>
                    <Styled.ModeratorsContainer>
                      <Styled.ModeratorsCount>{statusesLegend[1].data}</Styled.ModeratorsCount>
                      <Styled.ModeratorsLabel>{statusesLegend[1].title}</Styled.ModeratorsLabel>
                    </Styled.ModeratorsContainer>
                    <Styled.TeachersContainer>
                      <Styled.TeachersCount>{statusesLegend[2].data}</Styled.TeachersCount>
                      <Styled.TeachersLabel>{statusesLegend[2].title}</Styled.TeachersLabel>
                    </Styled.TeachersContainer>
                  </Styled.ModeratorsTeachersContainer>
                  <Styled.ModeratorsTeachersContainer>
                    <Styled.ActiveContainer>
                      <Styled.ModeratorsCount>{statusesLegend[3].data}</Styled.ModeratorsCount>
                      <Styled.ModeratorsLabel>{statusesLegend[3].title}</Styled.ModeratorsLabel>
                    </Styled.ActiveContainer>
                    <Styled.OthersContainer>
                      <Styled.TeachersCount>{statusesLegend[4].data}</Styled.TeachersCount>
                      <Styled.TeachersLabel>{statusesLegend[4].title}</Styled.TeachersLabel>
                    </Styled.OthersContainer>
                  </Styled.ModeratorsTeachersContainer>
                </>
              ) : null}
            </Styled.DataContainer>
          )}

          {tab === 1 && (
            <Styled.LegendInnerWrapper>
              <Styled.SelectorWrapper>
                <Autocomplete
                  label="Тип информации"
                  options={infoOptions}
                  size={Size.small}
                  value={infoType.label}
                  onChange={handleChange}
                  disabled={type === "statuses"}
                />
              </Styled.SelectorWrapper>
              <Styled.InfoItemsWrapper>
                <Styled.InfoItems>
                  <LegendCard
                    label={event?.title}
                    legend={rangeType === "all" ? statusesLegend : [statusesLegend[0]]}
                  />
                </Styled.InfoItems>
              </Styled.InfoItemsWrapper>
            </Styled.LegendInnerWrapper>
          )}
        </Styled.LegendWrapper>
      )}
    </>
  )
}

export { RequestsAndStatusesLegend }
