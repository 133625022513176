import React, { useEffect, useState } from "react"
import { Button, Modal } from "shared/ui"
import { FormControlLabel, Radio, Typography } from "@mui/material"
import { AdaptiveTestFeedback, QuestionsPull, resetTest, useAdaptiveTest } from "entities/AdaptiveTest"
import {
  AdaptiveTestContainer,
  ContentModalWrapper,
  ModalNextTestWrapper,
  StyledReadySetter,
  StyledSendAnswersContainer,
} from "./styles"
import { TimerWrapper } from "entities/Test/ui/TestBlock.styled"
import { Timer } from "shared/assets"
import { useTimer } from "react-timer-hook"
import { parseTime, timerRestart } from "../../entities/AdaptiveTest/lib/lib"
import ImageTest from "images/CompletedAdaptiveTest.svg"
import { InfoIcon } from "../../images/icons/InfoIcon"
import { useTheme } from "../../shared/context"

interface IProps {
  deadline: null | string
  id: number
  index: number
  is_active: boolean
  is_required: boolean
  max_score: number
  min_score: number
  module: number
  props: string
  stage: number
  subsection: number
  title: string
}

export const AdaptiveTestBlock = ({
  props,
  changeSubsection,
  showButtonRestart,
}: {
  props: IProps
  changeSubsection: (c: number) => void
  showButtonRestart: boolean
}) => {
  const theme = useTheme()
  const [openModal, setOpenModal] = useState(false)
  const [ready, setIsReady] = useState(false)
  const [testProps, setTestProps] = useState<{
    timer: string
    steps: number
    finish: boolean
    autostart: boolean
    test_description: string
  }>(JSON.parse(props.props))
  // Таймер для переключения между разделами (тестами)
  const nextTestTimer = useTimer({
    expiryTimestamp: parseTime("00:00:15"),
    autoStart: false,
    onExpire: () => {
      setOpenModal(false)
      changeSubsection(1)
    },
  })

  useEffect(() => {
    setTestProps(JSON.parse(props.props))
  }, [props.id])

  // Моделька для хранения бизнес логики разделов
  const {
    time,
    answers,
    startAdaptiveTest,
    currentStep,
    finishStep,
    submission,
    questionsPull,
    goToNextStep,
    timerExpire,
    setAnswers,
    loadingAnswers,
  } = useAdaptiveTest({ changeSubsection: nextTest, content_block: props.id, testProps: testProps })

  const isAllAnswersFilled = Object.keys(answers).every(key => {
    const id = parseInt(key)
    return (answers[id] as string[]).every(answer => answer && answer.trim() !== "")
  })

  // Функция сброса теста
  function handleClickReset() {
    resetTest(props.id).then(() => {
      window.location.reload()
    })
  }

  // Функция открытия модалки для переключения между разделами
  function nextTest() {
    setOpenModal(true)
    timerRestart([0, 0, 15], nextTestTimer)
  }

  return (
    <AdaptiveTestContainer>
      <Modal
        isOpen={openModal}
        showCloseButton={showButtonRestart}
        bgColor={"#F0F2F9"}
        onClose={
          showButtonRestart
            ? () => {
                setOpenModal(false)
              }
            : () => {}
        }
      >
        {/*Модалка для переключения между разделами*/}
        <ModalNextTestWrapper>
          <InfoIcon />
          <ContentModalWrapper>
            <Typography variant={"h2"}>Подготовка к прохождению Reading</Typography>
            <Typography variant={"body1"} fontWeight={"small"} fontSize={"medium"}>
              Вы завершили раздел Listening.
              <span style={{ display: "block", marginBottom: "20px" }} />
              Прохождение Reading начнётся после завершения таймера, отображённого ниже.
            </Typography>
          </ContentModalWrapper>
        </ModalNextTestWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "32px",
          }}
        >
          <Timer timerState={nextTestTimer} initialTime={"00:00:05"} />
        </div>
        <div
          style={{
            marginTop: "40px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={ImageTest as any} alt="Completed Listening image" />
        </div>
      </Modal>
      {showButtonRestart && <Button onClick={handleClickReset}>Перезапустить тест (для отладки)</Button>}
      {!!submission && testProps.finish && <AdaptiveTestFeedback submission={{ score: submission.score, text: "" }} />}
      {/*0 шаг теста*/}
      {currentStep === -1 && !!testProps.test_description && (
        <>
          <Typography variant={"body1"} fontSize={"medium"} fontWeight={"medium"}>
            <div dangerouslySetInnerHTML={{ __html: testProps.test_description || "" }}></div>
          </Typography>
          {!submission && (
            <>
              <StyledReadySetter>
                <Typography sx={{ color: "#313439" }} fontSize={"medium"} fontWeight={"medium"} variant={"body1"}>
                  1. Пожалуйста, подтвердите Ваше согласие с правилами проведения.
                </Typography>
                <FormControlLabel
                  control={<Radio checked={ready} onChange={(event, checked) => setIsReady(checked)} />}
                  label={
                    <Typography variant={"body1"} fontWeight={"medium"} fontSize={"small"} sx={{ color: "#313439" }}>
                      Я прочитал информацию и готов к выполнению теста
                    </Typography>
                  }
                />
              </StyledReadySetter>
              <Button
                /*@ts-ignore*/
                sx={{ alignSelf: "center", [theme.breakpoints.down("sm")]: { width: "100%" } }}
                variant={"contained"}
                disabled={!ready || loadingAnswers}
                color={"primary"}
                onClick={startAdaptiveTest}
              >
                Начать прохождение
              </Button>
            </>
          )}
        </>
      )}
      {currentStep !== -1 && !finishStep && !!questionsPull && (
        <>
          <UseTestTimer time={time} timerExpire={timerExpire} />
          <QuestionsPull questions={questionsPull.questions} setAnswers={setAnswers} answers={answers} />
          <StyledSendAnswersContainer>
            {!isAllAnswersFilled && (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                Ответьте на все вопросы, прежде чем перейти к следующей части
              </div>
            )}
            <Button
              variant={Number(testProps.steps) - 1 === Number(currentStep) ? "contained" : "outlined"}
              color={"primary"}
              sx={{ alignSelf: "flex-end" }}
              onClick={goToNextStep}
              disabled={
                Object.keys(answers).length < questionsPull.questions.length || !isAllAnswersFilled || loadingAnswers
              }
            >
              {Number(testProps.steps) - 1 === Number(currentStep)
                ? "Отправить ответы"
                : `Перейти к части ${currentStep + 2}`}
            </Button>
            {Number(testProps.steps) === Number(currentStep) && (
              <Typography variant={"caption"} fontWeight={"medium"} sx={{ width: "50%" }}>
                После отправки ответов ваша попытка выполнения данного раздела будет завершена. Внимательно проверьте,
                что вы выбрали нужные варианты ответов.
              </Typography>
            )}
          </StyledSendAnswersContainer>
        </>
      )}
    </AdaptiveTestContainer>
  )
}

const UseTestTimer = ({ time, timerExpire }: { time: string; timerExpire: () => void }) => {
  const timerState = useTimer({
    expiryTimestamp: parseTime(time),
    autoStart: false,
    onExpire: timerExpire,
  })

  useEffect(() => {
    const new_time = time.split(":").map(e => Number(e)) || [0, 0, 0]
    timerRestart(new_time, timerState)
  }, [time])

  return (
    <TimerWrapper>
      <Timer timerState={timerState} initialTime={"00:00:00"} />
    </TimerWrapper>
  )
}
