import { styled } from "@mui/material/styles"
import theme from "tailwindcss/defaultTheme"

export const BackgroundContainer = styled('div')(({theme}) => ({
  paddingLeft: theme.spacing(31),
  paddingRight: theme.spacing(31),
  width: "100%",
  backgroundColor: "#F9F9F9",
  marginTop: theme.spacing(18),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}))

export const PolicyTextContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(13),
  maxWidth: theme.spacing(256),
  margin: "0 auto",
  width: '100%',
  color: "#313439",
}))

export const DocumentTitle = styled("p")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.spacing(4.5),
  textAlign: "center",
}))

export const ParagraphTitle = styled("p")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.spacing(4.5),
}))

export const Paragraph = styled("p")(({ theme }) => ({
  fontSize: theme.spacing(4.5),
  hyphens: "auto",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  textAlign: "justify",
}))
