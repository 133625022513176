import { makeStyles } from "@mui/styles"

export default makeStyles(theme => ({
  reviewsContainer: {
    overflowX: "hidden",
    paddingTop: 96,
    paddingBottom: 96,
    paddingLeft: 124,
    paddingRight: 124,
    marginTop: 64,
    gap: 48,
    width: "100%",
    height: 760,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundPosition: "center",
    backgroundSize: "cover",
    "& .css-ohwg9z": {
      overflow: "unset",
    },
    "& .css-1abc02a": {
      right: "-124px",
      [theme.breakpoints.down("md")]: {
        right: "-100px",
      },
      [theme.breakpoints.down("sm")]: {
        right: "-20px",
      },
    },
    "& .css-hn784z": {
      left: "-124px",
      [theme.breakpoints.down("md")]: {
        left: "-100px",
      },
      [theme.breakpoints.down("sm")]: {
        left: "-20px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      gap: 88,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(40),
      paddingRight: theme.spacing(40),
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingLeft: 100,
      paddingRight: 100,
      height: 680,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
      height: 760,
    },
  },

  reviewsCarousel: {
    "& > div > div > div > div > div > div": {
      borderRadius: 12,
    },
    "& div p": {
      fontFamily: "Roboto",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "155%",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& > div > div > div > div > div > div > div > div > img": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    "& > div > div > div > div > div > div > div ": {
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    "& > div > div > div > div > div > div > div > div": {
      [theme.breakpoints.down("md")]: {
        flexBasis: 0,
        padding: 0,
        margin: 0,
        maxWidth: "max-content",
      },
    },
    "& > div > div > div > div > div > div > div > div:first-child": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },

  navButtons: {
    background: "transparent",
    boxSizing: "content-box",
    padding: 10,
    margin: "0 20px",
    width: 32,
    height: 32,
    backgroundColor: "transparent",
    "& svg": {
      width: "240%",
      height: "240%",
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
    },
  },
}))
