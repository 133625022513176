import * as Styled from "entities/Test/ui/TestBlock.styled.ts"
import React, { useEffect, useState, memo } from "react"
import useStyles from "./styles"
import useTestBlock from "entities/Test/model/useTestBlock"
import useTimerTest from "entities/Test/model/useTimerTest"
import { CircularProgress, FormControl, FormLabel, Typography } from "@mui/material"
import { SubmissionFeedback } from "features"
import {
  TimerStartModal,
  TimerInfoModal,
  TimerResultsModal,
  TimerExitModal,
  TimerExpiredModal,
  TimerSubmitModal,
} from "widgets"
import { getActualSubmission, submitAssigment } from "entities/Assignment/api/assignment"
import { Info, parseDescription, parseTitle } from "entities/Test/ui/TestBlockElements"
import { AwaitingDialog } from "shared/assets"
import { Timer } from "shared/assets"
import { TestHeader } from "entities/Test/ui/TestHeader"
import { TestTimerHeader } from "entities/Test/ui/TestTimerHeader"
import {
  stringifyScore,
  parseFeedback,
  formTaskSubmissions,
  checkIsTestEmpty,
  parseKeys,
} from "entities/Test/lib/test.ts"
import { useDispatch, useSelector } from "react-redux"
import { timerActions, testActions } from "shared/store"
import { ResetTestButton } from "entities/Test/ui/ResetTestButton"
import { HiddenContent } from "entities/Test/ui/HiddenContent"
import { useTheme } from "../../shared/context"
import { styled } from "@mui/material/styles"
import { Button } from "../../shared/ui"

export const TestBlock = React.memo(props => {
  const theme = useTheme()
  const { block, team, manual: isManual, courseSlug } = props
  const minScore = block.min_score
  const totalScore = block.max_score
  const testProps = JSON.parse(block.props)
  const attempts = { value: testProps.attempts, type: testProps.attempts_type, timer: testProps.timer }
  const isTimer = Boolean(attempts.timer)
  const classes = useStyles()
  const [scores, setScores] = useState({})
  const [helperText, setHelperText] = useState(" ")
  const [showHeader, setShowHeader] = useState(false)
  const [submission, setSubmission] = useState(null)
  const [isLoadedSubmission, setIsLoadedSubmission] = useState(false)
  const [isLoadedDealine, setIsLoadedDeadline] = useState(false)
  const [isReachPredeadline, setIsReachPredeadline] = useState(false)
  const [isReachDeadline, setIsReachDeadline] = useState(false)
  const [isResultsModal, setIsResultsModal] = useState(false)
  const [isExpiredModal, setIsExpiredModal] = useState(false)
  const [isInfoModal, setIsInfoModal] = useState(false)
  const [isStartTimerModal, setIsStartTimerModal] = useState(false)
  const [isSubmitTimerModal, setIsSubmitTimerModal] = useState(false)
  const [isHiddenContent, setIsHiddenContent] = useState(false)
  const [isStartContent, setIsStartContent] = useState(false)
  const {
    startNewTimer,
    timerState,
    isDisplayTimer,
    isLeftTime,
    isTimeElapsed,
    setIsDisplayTimer,
    isDisplayTest,
    setIsDisplayTest,
  } = useTimerTest({
    block,
    attempts,
    handleSubmit,
    isTimer,
    isReachPredeadline,
    isLoadedSubmission,
    isLoadedDealine,
    setIsStartTimerModal,
    setIsResultsModal,
    setIsExpiredModal,
    setIsReachPredeadline,
  })
  const {
    currentTeam,
    tests,
    isLoading,
    selectedAnswers,
    setSelectedAnswers,
    results,
    setResults,
    setReady,
    setIsLoading,
    questionMapper,
    sendFile,
    handleErrors,
    fileInput,
    clearLocalStore,
    handleResultModal,
  } = useTestBlock({
    isTimer,
    isManual,
    isReachDeadline,
    isTimeElapsed,
    setIsReachDeadline,
    setIsReachPredeadline,
    setIsLoadedDeadline,
    team,
    block,
    setHelperText,
    setShowHeader,
  })

  const [score, setScore] = useState(0)
  const [submissionAttempts, setSubmissionAttempts] = useState(null)
  const [isResults, setIsResults] = useState(false)
  const [showTimerHeader, setShowTimerHeader] = useState(false)
  const isUploadingFile = useSelector(state => state.test.isUploadingFile)
  const isDisplaySubmission =
    (!isTimer || isReachDeadline) && isManual && submission && !isResultsModal && !isStartContent
  const dispatch = useDispatch()
  dispatch(timerActions.setTestData({ attempts, courseSlug }))
  const user = localStorage.getItem("user_status")
  const isAdmin = user === "admin"

  useEffect(() => {
    restartTest()
    formSubmission()
  }, [block.id])

  async function restartTest() {
    setSubmission(null)
    setReady(true)
    setShowHeader(false)
    setShowTimerHeader(false)
    setIsResults(false)
    setHelperText(" ")
    setIsLoadedSubmission(false)
    setIsLoadedDeadline(false)
    setScores({})
    setScore(0)
    setIsHiddenContent(false)
    setIsStartContent(false)
    setResults(new Array(tests.length).fill(null))
    setSelectedAnswers(prev => {
      const resetedAnswers = tests.map((test, index) =>
        test.type === "sort" || test.type === "match" ? prev[index] : {}
      )
      return resetedAnswers
    })
  }

  async function handleSubmit(openResultModal, preventSubmitModal) {
    const isEmptyCheck = !isTimer || isLeftTime
    if (isEmptyCheck) {
      const isEmpty = checkIsTestEmpty(selectedAnswers, tests)
      if (isEmpty) {
        isTimer && !preventSubmitModal && setIsSubmitTimerModal(true)
        setHelperText("Пожалуйста, ответьте хотя бы на один вопрос из теста.")
        return
      }
    }
    setHelperText("Пожалуйста, не закрывайте страницу. Идет отправка ответов.")
    setIsLoading(true)
    for (let index = 0; index < tests.length; index++) {
      if (isEmptyCheck && tests[index].type === "attach") {
        if (fileInput.current[index] && fileInput.current[index].files && fileInput.current[index].files[0]) {
          if (fileInput.current[index].files[0].size > 25 * 1024 * 1024) {
            setHelperText("Файл не отправлен, так как превышен допустимый размер (25 мб)")
            setIsLoading(false)
            return
          }
        } else {
          isTimer && !preventSubmitModal && setIsSubmitTimerModal(true)
          setHelperText("Пожалуйста, прикрепите все файлы")
          setIsLoading(false)
          return
        }
      }
    }
    const formedAnswers = formTaskSubmissions(tests, selectedAnswers)
    try {
      const payload = {
        task_submissions: formedAnswers,
        content_block: block.id,
        course: block.course,
        team: team,
      }
      await submitAssigment(payload).then(res => sendFiles(res))
      await formSubmission()
      if (isTimer && openResultModal) {
        setIsResultsModal(true)
        setIsDisplayTimer(false)
        setIsDisplayTest(false)
        timerState.restart()
      }
    } catch (err) {
      handleErrors(err.response.status)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
        window.scrollTo(0, 0)
      }, 500)
    }
  }

  async function sendFiles(response) {
    let isFile = false
    let uploadPromises = []
    for (let index = 0; index < tests.length; index++) {
      if (
        tests[index].type === "attach" &&
        fileInput.current[index] !== undefined &&
        typeof fileInput.current[index].files[0] != "undefined"
      ) {
        uploadPromises.push(sendFile(fileInput.current[index].files[0], index, response.id))
        isFile = true
      }
    }
    setAwaitingModal(true)
    try {
      await Promise.all(uploadPromises)
    } catch {
    } finally {
      setAwaitingModal(false)
    }
    if (!isFile) {
      setHelperText("Ответы отправлены")
      setShowHeader(!isTimer)
      if (block.deadline) {
        setIsResults(false)
      }
    }
  }

  async function formSubmission() {
    try {
      setSubmissionAttempts(null)
      const response = await getActualSubmission(block.id)
      if (response) {
        setSubmission(response)
        setScores(Object.fromEntries(response.task_submissions.map(e => [e.task, e.score])))
        setScore(response.score)
        if (!isManual) {
          const sortedSubmissions = response.task_submissions.sort((a, b) =>
            a.component.index > b.component.index ? 1 : -1
          )
          setResults(sortedSubmissions.map(e => parseFeedback({ score: e.score, maxScore: e.component.score })))
          const answers = parseKeys(sortedSubmissions)
          setSelectedAnswers(answers)
          setSubmissionAttempts(response.attempts + 1)
          !isTimer && setShowHeader(true)
        }
      } else {
        setSubmissionAttempts(1)
        setSubmission(null)
        setSubmissionAttempts(1)
      }
    } catch {
    } finally {
      setIsLoadedSubmission(true)
    }
  }

  const onCheckResults = () => {
    setIsResultsModal(false)
    setIsDisplayTest(isReachDeadline)
    setShowTimerHeader(true)
    handleSubmit()
    setHelperText("")
    clearLocalStore()
    handleResultModal()
  }

  const toggleInfoModal = () => {
    setIsInfoModal(prev => !prev)
  }

  const setAwaitingModal = condition => {
    dispatch(testActions.setIsUploadingFile(condition))
  }

  const handleModalClosing = (setModalState, setHiddenState) => {
    setModalState(false)
    setHiddenState(true)
  }

  if (!tests || !selectedAnswers || !results) return <></>

  // ToDo переделать текстовки рядом с кнопкой

  function getAllAttempts() {
    let attempts = JSON.parse(block.props).attempts
    if (Number(attempts)) {
      if (submissionAttempts <= attempts) {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: "16px", marginTop: 12 }}>
            <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
              Попытка:
            </Typography>
            <Typography variant={"h4"}>{`${submissionAttempts} из ${attempts}`}</Typography>
          </div>
        )
      } else return `Вы израсходовали все попытки`
    } else if (!Number(attempts)) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: "16px", marginTop: 12 }}>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            Попытка:
          </Typography>
          <Typography variant={"h4"}>{`${submissionAttempts}`}</Typography>
        </div>
      )
    }
  }

  return (
    <Styled.Container>
      {!isManual && !isTimer && block && submissionAttempts && !showHeader && (
        <div style={{ display: "flex", alignItems: "center", gap: 8, marginBottom: "16px" }}>
          <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
            {getAllAttempts()}
          </Typography>
        </div>
      )}
      <AwaitingDialog open={isUploadingFile}>Пожалуйста, не закрывайте страницу. Идет отправка ответов.</AwaitingDialog>
      <TimerStartModal
        isOpen={isStartTimerModal}
        onClose={() => handleModalClosing(setIsStartTimerModal, setIsStartContent)}
        onStart={startNewTimer}
        attempts={attempts}
        courseSlug={courseSlug}
        blockId={block.id}
      />
      <TimerSubmitModal
        isOpen={isSubmitTimerModal}
        onClose={() => setIsSubmitTimerModal(false)}
        onSubmit={handleSubmit}
        selectedAnswers={selectedAnswers}
        tests={tests}
        fileInput={fileInput.current}
      />
      <TimerExitModal />
      <TimerInfoModal isOpen={isInfoModal} onClose={toggleInfoModal} attempts={attempts} />
      <TimerResultsModal
        isOpen={isResultsModal}
        onClose={() => handleModalClosing(setIsResultsModal, setIsStartContent)}
        onResults={onCheckResults}
        courseSlug={courseSlug}
        blockId={block.id}
      />
      <TimerExpiredModal
        isOpen={isExpiredModal}
        onClose={() => handleModalClosing(setIsExpiredModal, setIsHiddenContent)}
        courseSlug={courseSlug}
      />
      {/* {isAdmin && <ResetTestButton blockId={block.id} />} */}
      <form
        onSubmit={event => {
          event.preventDefault()
          handleSubmit(isTimer)
        }}
        className={classes.test}
      >
        {showHeader && (
          <TestHeader
            score={score}
            isManual={isManual}
            minScore={minScore}
            totalScore={totalScore}
            restartTest={restartTest}
            hasAttempts={!isManual && !isTimer}
            disabledClear={!isManual && !isTimer && Number(JSON.parse(block.props).attempts) === submission?.attempts}
            textAttempts={`${submissionAttempts - 1} ${
              Number(JSON.parse(block.props).attempts) ? "из " + Number(JSON.parse(block.props).attempts) : ""
            }`}
          />
        )}
        {isDisplaySubmission && <SubmissionFeedback submission={submission} />}
        {showTimerHeader && <TestTimerHeader deadline={block.deadline} isDeadline={isReachDeadline} score={score} />}
        {isTimer && isDisplayTimer && (
          <Styled.TimerWrapper>
            <Timer timerState={timerState} initialTime={attempts.timer} /> <Info onActivateModal={toggleInfoModal} />
          </Styled.TimerWrapper>
        )}
        {tests.map((e, index) => (
          <FormControlComponent
            e={e}
            index={index}
            isDisplayTest={isDisplayTest}
            classes={classes}
            questionMapper={questionMapper}
            submission={submission}
            scores={scores}
            isManual={isManual}
          />
        ))}
        <HiddenContent isHiddenContent={isHiddenContent} isStartContent={isStartContent} />
        {isDisplayTest && (
          <Styled.FlexRow sx={{ [theme.breakpoints.down("sm")]: { flexDirection: "column", gap: theme.spacing(2) } }}>
            {isLoading ? (
              <CircularProgress size={26} />
            ) : (
              !isResults && (
                <Button
                  sx={{ [theme.breakpoints.down("sm")]: { width: "100%" } }}
                  disabled={isTimer ? !timerState.isRunning : false}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.testButtonSubmit}
                  style={{ float: "left", marginTop: 25 }}
                >
                  Отправить
                </Button>
              )
            )}
            <Styled.FormHelper>{helperText}</Styled.FormHelper>
            {!!currentTeam && helperText === " " && (
              <Typography
                variant={"caption"}
                fontWeight={"small"}
                fontSize={"medium"}
                sx={{ margin: "25px 0 0 0", [theme.breakpoints.down("sm")]: { margin: 0 } }}
              >{`Вы отправляете результаты как участник команды ${currentTeam?.title}`}</Typography>
            )}
          </Styled.FlexRow>
        )}
      </form>
    </Styled.Container>
  )
})

const FormControlComponent = memo(
  ({ e, index, isDisplayTest, isManual, classes, questionMapper, submission, scores }) => {
    const theme = useTheme()
    if (!isDisplayTest) return

    return (
      <QuestionMainContainer>
        <FormControl
          component="fieldset"
          style={{ marginTop: 18, marginBottom: 50, visibility: isDisplayTest ? "visible" : "hidden" }}
        >
          <FormLabel
            component="legend"
            sx={{
              color: "black",
              maxWidth: 800,
              lineHeight: 1.3,
              [theme.breakpoints.down("sm")]: { maxWidth: "100%" },
            }}
          >
            <Styled.ScoreWrapper>
              <div className={classes.testQuest}> {parseTitle(e.text, index, e.type)}</div>
            </Styled.ScoreWrapper>
            {!isManual && (
              <Typography className={classes.testPoints}>
                {submission ? stringifyScore(scores[e.task], e.score) : `(Баллы 0/${e.score})`}
              </Typography>
            )}
            <Typography className={classes.testNotice}>{parseDescription(e.description)}</Typography>
          </FormLabel>
          <div>
            <img src={e.image} className={classes.testImg} />
            {questionMapper(e, index)}
          </div>
        </FormControl>
      </QuestionMainContainer>
    )
  }
)

const QuestionMainContainer = styled("div")(({ theme }) => ({
  width: theme.spacing(200),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))
