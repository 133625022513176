import React from "react"
import {
  Box,
  Tooltip,
  Drawer as MuiDrawer,
  ListItemButton,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"

import { styled } from "@mui/material/styles"

import SidebarNav from "./SidebarNav"
import { useTheme } from "shared/context"

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  borderRight: 0,
  "> div": {
    borderRight: 0,
    width: "25vw",
    minWidth: 240,
    backgroundColor: theme.palette.background.sidebar,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      minWidth: "90vw",
    },
  },
}))

const Brand = styled(ListItemButton)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.background.sidebar,
  fontFamily: theme.typography.fontFamily,
  minHeight: 56,
  padding: theme.spacing(3),
  justifyContent: "center",
  cursor: "pointer",
  flexGrow: 0,
  marginTop: 75,
  [theme.breakpoints.up("sm")]: {
    minHeight: 128,
  },
  "&:hover": {
    backgroundColor: theme.palette.background.sidebar,
  },
}))

function Progress(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 4 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export const Sidebar = ({ showFooter = false, ...props }) => {
  var subsections = props.content.sections.sort((a, b) => (a.index > b.index ? 1 : -1))

  const theme = useTheme()

  if (!props.showSidebar) {
    return (
      <Drawer
        sx={{ width: "500px !important" }}
        variant="permanent"
        {...props}
        PaperProps={{
          style: {
            width: "500px",
            border: "none",
            translate: "-92% 0",
            transition: "0.5s ease",
            cursor: "pointer",
            zIndex: 1000,
          },
        }}
        onClick={() => props.setShowSidebar(true)}
      >
        <div
          style={{
            minWidth: "500px",
            height: "100%",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              width: "100px",
              writingMode: "vertical-lr",
              transform: "rotate(180deg)",
              backgroundColor: "#1B2430",
              color: "white",
              marginTop: 80,
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 2,
              fontWeight: 500,
              fontSize: 16,
              borderRadius: "4px 0 0 0",
            }}
          >
            Меню модуля
          </Typography>
          <IconButton
            aria-label="toggle"
            style={{
              height: "36px",
              width: "36px",
              marginLeft: "auto",
              marginTop: "8px",
              padding: 0,
            }}
          >
            <ArrowForwardIosIcon
              style={{
                color: "white",
                backgroundColor: "#1565C0",
                fontSize: 26,
                height: "100%",
                width: "100%",
                borderRadius: "6px",
              }}
            />
          </IconButton>
        </div>
      </Drawer>
    )
  }

  return (
    <Drawer
      variant="permanent"
      {...props}
      PaperProps={{
        style: {
          border: "none",
          translate: !props.showSidebar && "-92% 0",
          transition: "0.5s ease",
          zIndex: 1000,
        },
      }}
    >
      <Brand>
        <Box ml={1} mt={2} p={2} style={{ width: "100%", marginLeft: 18 }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Tooltip title={props.content.title}>
              <Typography variant="subtitle3">{props.content.title}</Typography>
            </Tooltip>
          </div>
          {props.content.max_score > 0 && (
            <div style={{ display: props.hasAdaptiveTest ? "none" : "" }}>
              <Progress
                color="primary"
                variant="determinate"
                value={
                  props.content.max_score
                    ? Math.round(((props.progress.masked_score || 0) / props.content.max_score) * 100)
                    : 0
                }
              />
              <Typography variant="body2">
                {`${props.progress.masked_score || 0}/${props.content.max_score || 0} (минимум: ${
                  props.content.min_score
                })`}
              </Typography>
            </div>
          )}
          {props.content.max_score === 0 && (
            <Typography sx={{ marginTop: theme.spacing(4) }} variant={"body2"}>
              Модуль без прогресса выполнения
            </Typography>
          )}
        </Box>
      </Brand>
      <SidebarNav
        showSidebar={props.showSidebar}
        setShowSidebar={props.setShowSidebar}
        hasAdaptiveTest={props.hasAdaptiveTest}
        subsections={subsections}
        setCurrent={props.setCurrent}
        courseSlug={props.content.course_slug}
      />
    </Drawer>
  )
}
