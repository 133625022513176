import React, { useEffect, useRef } from "react"
import useStyles from "./styles"
import { Button, Line } from "shared/ui"
import { Typography } from "@mui/material"

const DescriptionCompany = ({ e, handleChangeRef, reverse }) => {
  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    handleChangeRef(ref, e.index)
  }, [ref])

  return (
    <div
      ref={ref}
      className={reverse ? classes.reverseDescriptionCompanyContainer : classes.descriptionCompanyContainer}
    >
      <div
        className={classes.descriptionTextContainer}
        style={{
          borderRadius: "24px",
          padding: e.backgroundTextColor ? "48px" : "0px",
          backgroundColor: e.backgroundTextColor ? e.backgroundTextColor : "transparent",
        }}
      >
        {!!e.H2 && (
          <div>
            <Typography variant={"h2"} sx={{ color: e.H2.color }}>
              {e.H2.text}
            </Typography>
          </div>
        )}
        {!!e.Text && (
          <Typography variant={"body1"}>
            <div dangerouslySetInnerHTML={{ __html: e.Text.text }}></div>
          </Typography>
        )}
        {!!e.Button && (
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => !!e.Button.href && window.open(e.Button.href, "_blank")}
            sx={{ backgroundColor: e.Button.backgroundColor, color: e.Button.Color, width: "fit-content" }}
          >
            {e.Button.text || "Призыв к действию"}
          </Button>
        )}
      </div>
      {!!e.Image && <img src={e.Image.url} className={classes.imageDescriptionCompany} />}
    </div>
  )
}

export default DescriptionCompany
