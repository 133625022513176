import React, { FC, useState, useEffect } from "react"
import {
  Collaboration,
  FooterContainer,
  FooterWrapper,
  LeftFooterElement,
  LeftFooterImages,
  LeftFooterText1,
  LeftFooterText2,
  LinkElement,
  MiddleFooterElement,
  MiddleFooterInternalLink,
  RightFooterElement,
  RightFooterIconLinkContainer,
  RightFooterLinks,
  RightFooterPoliciesContainer,
  MobileFooterPoliciesContainer, RightFooterText,
  ImgTBank,
  ImgPayingSystems,
} from "./ui/Footer.styles"
import { IconButton, Tooltip, Typography } from "@mui/material"
import { RequestsModal } from "widgets"

import iconEmail from "images/icons/icon_email.svg"
import iconTelegram from "images/icons/icon_telegram.svg"
import iconTBank from "images/icons/t-bank.svg"
import iconPayingSystems from "images/icons/paying_systems.svg"
import { useUserState } from "../../shared/context"

export const Footer = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const { isAuthenticated, login } = useUserState()

  const leftFooterIcons = [
    { src: iconTBank, alt: "T Bank Icon" },
    { src: iconPayingSystems, alt: "Paying Systems Icons" },
  ];

  function handleCopyToClipboard(email: string, setTooltipOpen: React.Dispatch<React.SetStateAction<boolean>>) {
    navigator.clipboard.writeText(email).then(() => {
      setTooltipOpen(true)
      setTimeout(() => setTooltipOpen(false), 2000)
    })
  }

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <FooterContainer>
      <FooterWrapper>
        <LeftFooterElement item xs={12} md={4} lg={3}>
          <LeftFooterText1 >
            Syncwoia. Киберфизическая платформа навыков будущего
          </LeftFooterText1>
          <LeftFooterText2>
            {`\u00A9 ООО "Битроникс" ${new Date().getFullYear()}. Все права защищены`}
          </LeftFooterText2>
          <MobileFooterPoliciesContainer>
            <LinkElement href="/policy">
              Политика конфиденциальности
            </LinkElement>
            <LinkElement href="/terms">
              Пользовательское соглашение
            </LinkElement>
          </MobileFooterPoliciesContainer>
          <LeftFooterImages>
            {leftFooterIcons.map((icon, index) => (
              <img key={index} src={icon.src} alt={icon.alt} />
            ))}
          </LeftFooterImages>
        </LeftFooterElement>
        <MiddleFooterElement item xs={12} md={4} lg={3}>
          <MiddleFooterInternalLink
            onClick={() => (window.location.href = `/catalog/`)}
          >
            Каталог
          </MiddleFooterInternalLink>
          <MiddleFooterInternalLink
            onClick={() => (
              (!isAuthenticated) ? login.setOpen(true) : window.location.href = `/app/profile/`
              )}
          >
            Профиль
          </MiddleFooterInternalLink>
          <Collaboration
            onClick={() => setIsOpenModal(true)}
          >
            Сотрудничество
          </Collaboration>
        </MiddleFooterElement>
        <RightFooterElement item xs={12} md={4} lg={3}>
          <RightFooterLinks>
            <RightFooterIconLinkContainer>
              <IconButton style={{
                background: "none",
                border: "none",
                padding: 0,
              }}
              disabled
              >
                <img src={iconTelegram} alt="Telegram Icon" />
              </IconButton>
              <LinkElement
                href="https://t.me/syncwoiacom"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  position: "relative",
                  paddingLeft: "12px",
                }}
              >
                t.me/syncwoiacom
              </LinkElement>
            </RightFooterIconLinkContainer>
            <RightFooterIconLinkContainer>
              <IconButton
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                }}
                disabled
              >
                <img src={iconEmail} alt="Email Icon" />
              </IconButton>
              <Tooltip
                title="Почта скопирована"
                placement="top"
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <LinkElement
                  href= {(innerWidth < 1024) ? "mailto:support@syncwoia.com" : undefined}
                  onClick={
                    innerWidth < 1024
                      ? () => {}
                      : () => handleCopyToClipboard("support@syncwoia.com", setTooltipOpen)
                  }
                  style={{ position: "relative", paddingLeft: "12px" }}
                >
                  support@syncwoia.com
                </LinkElement>
              </Tooltip>
            </RightFooterIconLinkContainer>
          </RightFooterLinks>
          <RightFooterText>
            141701, г. Долгопрудный, Лихачевский проезд, д. 4, стр 1, офис 415
          </RightFooterText>
          <RightFooterPoliciesContainer>
            <LinkElement href="/policy" target="_blank" rel="noopener noreferrer">
              Политика конфиденциальности
            </LinkElement>
            <LinkElement href="/terms" target="_blank" rel="noopener noreferrer">
              Пользовательское соглашение
            </LinkElement>
          </RightFooterPoliciesContainer>
        </RightFooterElement>
      </FooterWrapper>
      <RequestsModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
      />
    </FooterContainer>
  )
}