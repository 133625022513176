import React, { useEffect, useMemo, useState } from "react"
import { Chip as MuiChip, Button, Grid, Link, Tab as MuiTab, Tabs, tabsClasses, Typography } from "@mui/material"
import { useHistory, useLocation } from "react-router-dom"
import { useTab } from "shared/hooks/useTab"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import UTurnLeftOutlinedIcon from "@mui/icons-material/UTurnLeftOutlined"
import { styled } from "@mui/material/styles"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { Header, Footer } from "widgets"
import { StageItem } from "entities/Module/ui/StageItem"
import { StageItemEvent } from "entities/Module/ui/StageItemEvent"
import { sortStages } from "entities/Course"
import { Box } from "@mui/system"
import defaultImage from "../../images/event_stage_in-progress.jpg"
import defaultImageLow from "../../images/event_stage_in-progress_low.jpg"
import { LeaderboardBanner } from "entities/Statistic/ui/LeaderboardBanner"
import { Button as SharedButton, ModalSmallTemplate } from "shared/ui"
import { useTheme, useUserState } from "../../shared/context"
import * as Styled from "./Stages.styled"

const Tab = styled(MuiTab)({
  fontSize: 20,
  fontWeight: 400,
  color: "white",
  margin: 25,
  "&.Mui-selected": { color: "#FA5D55" },
  "&.Mui-disabled": { color: "#858585" },
})

const Chip = styled(MuiChip)({
  backgroundColor: "white",
})

const Stages = props => {
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const { tab, changeTab } = useTab("stages_tab", props.match.params.name)
  const [openPreAuthDialog, setOpenPreAuthDialog] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const { isAuthenticated, user, login } = useUserState()
  const user_id = localStorage.getItem("user")
  const [stages, setStages] = useState(null)
  const [stageAccess, setStageAccess] = useState(false)
  const [blocks, setBlocks] = useState([])
  const [status, setStatus] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [hasCertificate, setHasCertificate] = useState(false)
  const [isActive, setIsActive] = useState(null)
  const [courseId, setCourseId] = useState(null)
  const [isLeaderboard, setIsLeaderboard] = useState(false)

  const today = useMemo(() => {
    return new Date()
  }, [props])
  const controller = new AbortController()

  function getBlocks(stages) {
    setBlocks(prev => {
      return []
    })
    axios
      .get("/content/modules/", { params: { stage: stages[tab].id, course: stages[tab].course } })
      .then(res => {
        setBlocks(res.data)
      })
      .catch(err => console.log(err))
  }

  function stringifyDates(start, finish) {
    let startDate = new Date(start)
    let finishDate = new Date(finish)
    let format = new Intl.DateTimeFormat("ru-RU", { month: "long", day: "numeric" })
    if (start === finish || !finish || finish.length === 0) {
      return format.format(startDate)
    }
    return `${format.format(startDate)} - ${format.format(finishDate)}`
  }

  useEffect(() => {
    if (!!user_id) {
      axios
        .get(`/content/actions/courses/my_status/`, {
          params: {
            course__slug: props.match.params.name,
          },
        })
        .then(res => {
          setStatus(res.data.status)
          setIsLogin(true)
          setCompleted(res.data.completed)
          setHasCertificate(res.data.has_certificate)
          setIsActive(res.data.is_active)
          axios.get("/content/courses/", { params: { slug: props.match.params.name } }).then(q => {
            setCourseId(q.data[0].id)
            const response = JSON.parse(q.data[0].team_props)?.is_leaderboard?.show === "true" || false
            setIsLeaderboard(response)
            axios.get(`/content/courses/${q.data[0].id}/`).then(res => {
              let resStages = res.data.stages.filter(e => !e.is_empty).sort(sortStages)
              setStages(resStages)
              goToFirstAccessible(resStages)
              axios.get(`/content/progress/my_progress/?course=${q.data[0].id}`).then(res => {
                setHasCertificate(res.data.participant.has_certificate)
              })
            })
          })
        })
        .catch(err => {})
    } else {
      setIsLogin(false)
      setOpenPreAuthDialog(true)
    }
  }, [user_id])

  useEffect(() => {
    if (!stages) return
    if (tab === null) return
    getBlocks(stages)
    axios
      .get("content/actions/participants/is_stage_accessible/", { params: { stage: stages[tab].id } })
      .then(res => {
        setStageAccess(res.data.detail)
      })
      .catch(err => {})
    return () => {
      controller.abort()
    }
  }, [stages, tab])

  function goToFirstAccessible(stages) {
    let t = null
    for (let index = 0; index < stages.length; index++) {
      if (new Date(stages[index].start) <= today && new Date(stages[index].finish).addDays(1) >= today) {
        if (isAccessible(stages[index])) {
          // && finish < today > start находим первый, в котором выполнено условие
          t = index
          break
        }
      }
    }
    if (t === null) {
      for (let index = 0; index < stages.length; index++) {
        if (isAccessible(stages[index])) {
          // && finish < today > start находим первый, в котором выполнено условие
          t = index
          break
        }
      }
    }
    if (tab !== null) {
      changeTab("", t)
    }
  }

  function getChip(label, color) {
    return (
      <Chip
        label={<Typography variant={"caption"}>{label}</Typography>}
        variant="outlined"
        style={{ color: color, borderColor: color, background: "transparent" }}
      />
    )
  }

  function getChips(block) {
    let chips = new Array(2)

    if (block.content_type === "content") {
      chips[0] = getChip("Модуль", "#98999C")
    } else if (block.content_type === "offline") {
      chips[0] = getChip("Событие", "#FFFFFF")
    } else if (block.content_type === "online") {
      chips[0] = getChip("Событие", "#FFFFFF")
    } else if (block.content_type === "hybrid") {
      chips[0] = getChip("Событие", "#FFFF")
    }

    if (block.type === "personal") {
      chips[1] = getChip("Для меня", "#28AE35")
    } else if (block.type === "team") {
      chips[1] = getChip("Для команды", "#317ACD")
    } else if (block.type === "global") {
      chips[1] = getChip("Для всех", "#313439")
    }

    return chips
  }

  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  function isAccessible(stage) {
    return new Date(stage.start) <= today || status === "teacher" || status === "moderator"
  }

  if (!!isLogin && (!stages || (!status && completed === null))) return null

  if (
    (!isActive && !!isLogin) ||
    (status !== "in_progress" &&
      status !== "mentor" &&
      status !== "teacher" &&
      status !== "moderator" &&
      status !== "admin" &&
      status !== "failed" &&
      !!isLogin)
  ) {
    const pathname = location.pathname.split("/").slice(0, -1).join("/")

    return (
      <div style={{ backgroundColor: "#F9F9F9" }}>
        <ModalSmallTemplate
          isOpen={true}
          title="Доступ закрыт"
          width={200}
          onClick={() => history.push(pathname)}
          onClose={() => history.push(pathname)}
          label={"На страницу события"}
        >
          Вам закрыт доступ на данное событие, поэтому вы не можете ознакомиться с траекторией. Если вы увидели это
          сообщение по ошибке, пожалуйста, свяжитесь с модератором события.
        </ModalSmallTemplate>
      </div>
    )
  }
  return (
    <div style={{ backgroundColor: "#F9F9F9", width: "100%" }}>
      <ModalSmallTemplate
        isOpen={openPreAuthDialog}
        title="Доступ закрыт"
        onClick={() => {
          setOpenPreAuthDialog(false)
          login.setOpen(true)
        }}
        onClose={() => (window.location.href = "/")}
        label={"Вход"}
      >
        Траектория доступна только авторизированным пользователям. Пожалуйста, войдите в платформу Syncwoia.
      </ModalSmallTemplate>
      {!!isLogin && (
        <>
          <Box
            sx={{
              "& span": { display: "flex !important", height: "40.104vw" },
              [theme.breakpoints.down("sm")]: { height: "auto", marginTop: "75px" },
            }}
          >
            <Header variant={"shadow"} />
            <LazyLoadImage
              src={stages[tab || 0].image || defaultImage}
              width="100%"
              placeholderSrc={
                stages[tab || 0].image
                  ? `${String(stages[tab || 0].image).slice(0, -4)}_low${String(stages[tab || 0].image).slice(-4)}`
                  : defaultImageLow
              }
              effect="blur"
            />
            <Button
              component={Link}
              href={`/event/${props.match.params.name}`}
              variant="contained"
              sx={{
                position: "absolute",
                left: "7%",
                top: "26vw",
                width: 400,
                height: 55,
                fontSize: 20,
                backgroundColor: "white",
                color: "#1565C0",
                [theme.breakpoints.down("sm")]: { display: "none" },
              }}
            >
              <UTurnLeftOutlinedIcon style={{ rotate: "90deg", color: "#1565C0", marginRight: 24 }} />
              ВЕРНУТЬСЯ К ОПИСАНИЮ
            </Button>
          </Box>
          <Tabs
            value={tab}
            onChange={changeTab}
            aria-label="event-tabs"
            textColor="primary"
            style={{ backgroundColor: "#313439", paddingLeft: "5%", paddingRight: "5%" }}
            TabIndicatorProps={{ style: { height: 10, backgroundColor: "#FA5D55" } }}
            scrollButtons={"auto"}
            variant={"scrollable"}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: Styled.StyledTabButton,
            }}
          >
            {stages.map(e => (
              <Tab
                disabled={!isAccessible(e)}
                label={`Этап ${stages.indexOf(e) + 1}`.toUpperCase()}
                value={stages.indexOf(e)}
              />
            ))}
          </Tabs>
          <div
            style={{
              minHeight: "100vh",
              padding: "5%",
              width: "100%",
            }}
          >
            {tab !== null && (
              <>
                <Styled.ControlTrajectoryContainer>
                  <CalendarMonthIcon fontSize="large" />
                  <Typography style={{ fontSize: 18, fontWeight: 700 }}>
                    {stringifyDates(stages[tab].start, stages[tab].finish)}
                  </Typography>
                  <Styled.ButtonsContainer>
                    {tab === stages.length - 1 && (stageAccess || stages[tab].access_type === "unrestricted") && (
                      <SharedButton
                        component={Link}
                        variant={hasCertificate ? "outlined" : "contained"}
                        disabled={!completed}
                        color={"primary"}
                        onClick={() => window.open(`/event/${props.match.params.name}/certificate`, "_blank")}
                      >
                        {hasCertificate ? "Открыть сертификат" : "Получить сертификат"}
                      </SharedButton>
                    )}
                    {status === "moderator" && (
                      <SharedButton
                        onClick={() => window.open(`/statistics`, "_blank")}
                        variant="contained"
                        color={"primary"}
                      >
                        Редактировать траекторию
                      </SharedButton>
                    )}
                    <SharedButton
                      onClick={() => window.open(`/event/${props.match.params.name}`, "_self")}
                      variant="outlined"
                      color={"primary"}
                      sx={{ [theme.breakpoints.up("sm")]: { display: "none" } }}
                    >
                      Вернуться к описанию
                    </SharedButton>
                  </Styled.ButtonsContainer>
                </Styled.ControlTrajectoryContainer>
                <Grid container spacing={6}>
                  {blocks
                    .sort((a, b) => (a.index > b.index ? 1 : -1))
                    .map((c, index) => (
                      <Grid item xs={12} md={6} lg={4} key={index} sx={{ width: "100%" }}>
                        {c.content_type === "content" ? (
                          <StageItem
                            chip={getChips(c)}
                            status={status}
                            stringifyDates={stringifyDates}
                            {...c}
                            index={index + 1}
                          />
                        ) : (
                          <StageItemEvent
                            chip={getChips(c)}
                            status={status}
                            stringifyDates={stringifyDates}
                            {...c}
                            index={index + 1}
                          />
                        )}
                      </Grid>
                    ))}
                </Grid>
                {blocks.length === 0 && (
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                    }}
                    variant="h3"
                  >
                    Нет доступных модулей
                  </Typography>
                )}
              </>
            )}
          </div>
          {isLeaderboard && <LeaderboardBanner courseId={courseId} />}
          <Footer />
        </>
      )}
    </div>
  )
}

export default Stages
