import React, { useRef, useState } from "react"
import useStyles from "./styles"
import { Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"

export const AdminStaticLoaderPannel = () => {
  var classes = useStyles()

  const [success, setSuccess] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filename, setFilename] = useState("")
  const [filePath, setFilePath] = useState("")
  const fileInput = React.createRef()
  const [isFileSelected, setIsFileSelected] = useState(false)

  const [deleteSuccess, setDeleteSuccess] = useState(null)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const [isHtmlFileSelected, setIsHtmlFileSelected] = useState(false)
  const [htmlCertificateSuccess, setHtmlCertificateSuccess] = useState(null)
  const [isHtmlCertificateLoading, setIsHtmlCertificateLoading] = useState(false)
  const [htmlCertificateFilename, setHtmlCertificateFilename] = useState("")
  const htmlCertificateFileInput = useRef(null)

  const [helperText, setHelperText] = useState("")

  function uploadAdminStatic() {
    setIsLoading(true)
    let file = fileInput.current.files[0]
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .post(`/upload-statics/`, file, {
        headers: {
          "Content-Type": "application/octet-stream",
          "Content-Disposition": `attachment; filename=${file.name}`,
        },
      })
      .then(res => {
        setSuccess(true)
        setFilename(res.data.link)
        fileInput.current.value = null
        setIsFileSelected(true)
        setIsLoading(false)
      })
      .catch(err => {
        setSuccess(false)
        setIsLoading(false)
      })
  }

  function deleteAdminStatic() {
    setIsDeleteLoading(true)
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    axios
      .put(`/delete-statics/`, { name: filePath })
      .then(res => {
        setDeleteSuccess(true)
        setIsDeleteLoading(false)
      })
      .catch(err => {
        setDeleteSuccess(false)
        setIsDeleteLoading(false)
      })
  }

  function uploadCertificateTemplate() {
    setIsHtmlCertificateLoading(true)
    let file = htmlCertificateFileInput.current.files[0]
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

    const formData = new FormData()
    formData.append("file", file)

    axios
      .post(`/content/upload-certificate-template/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setHtmlCertificateSuccess(true)
        setIsHtmlCertificateLoading(false)
        htmlCertificateFileInput.current.value = null
        setIsHtmlFileSelected(false)
      })
      .catch(err => {
        setHtmlCertificateSuccess(false)
        setIsHtmlCertificateLoading(false)
      })
  }

  return (
    <div style={{ width: 1200, padding: 48 }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ mb: 12 }}>
        Загрузка админской статики
      </Typography>
      <div style={{ display: "flex", alignItems: "center", outline: "gray", gap: 50 }}>
        <Button component="label" className={classes.checkButtonWt}>
          Выбрать файл
          <input
            type="file"
            hidden
            ref={fileInput}
            onChange={e => {
              if (!!e.target.files[0]) {
                setFilename(e.target.files[0].name)
                setIsFileSelected(true)
              } else {
                setIsFileSelected(false)
              }
              setSuccess(null)
            }}
          />
        </Button>
        {isLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button className={classes.checkButton} onClick={uploadAdminStatic} disabled={!isFileSelected}>
            Отправить
          </Button>
        )}
        <Typography className={classes.textFileName}>{filename}</Typography>
      </div>
      <Typography className={classes.textMess} style={success ? { color: "#36d136" } : { color: "#d13636" }}>
        {success === null ? "" : success ? "Отправлено" : "Ошибка при отправке"}
      </Typography>
      <Box mt={4} mb={4}>
        <Divider
          sx={{
            borderColor: "grey",
            borderWidth: "1px",
          }}
        />
      </Box>
      <Typography variant="h5" align="center" gutterBottom sx={{ mb: 12 }}>
        Удаление папки с админской статикой
      </Typography>
      <Typography variant="body1">
        На данный момент можно удалять только папки. Чтобы удалить папку по адресу
        https://syncwoia.com/static/media/extra/1/folder1/folder2/ нужно отправить ввести в поле следующую строку:
        folder1__folder2 и нажать Удалить. Число после extra не играет роли.
      </Typography>
      <TextField
        variant="outlined"
        value={filePath}
        onChange={e => setFilePath(e.target.value)}
        helperText="Введите строку вида folder1__folder2"
        fullWidth
        style={{ marginTop: 12, marginBottom: 12 }}
      />
      {isLoading ? (
        <CircularProgress size={26} />
      ) : (
        <Button className={classes.checkButton} onClick={deleteAdminStatic} disabled={filePath === ""}>
          Удалить
        </Button>
      )}
      <Box mt={24} mb={6}>
        <Divider
          sx={{
            borderColor: "grey",
            borderWidth: "1px",
          }}
        />
      </Box>
      <Typography variant="h5" align="center" gutterBottom sx={{ mb: 12 }}>
        Загрузка шаблонов сертификатов
      </Typography>
      <Typography variant="body1">Пожалуйста, загрузите HTML-файл шаблона сертификата.</Typography>
      <div style={{ display: "flex", alignItems: "center", outline: "gray", gap: 50, marginTop: 12 }}>
        <Button component="label" className={classes.checkButtonWt}>
          Выбрать шаблон
          <input
            type="file"
            hidden
            ref={htmlCertificateFileInput}
            onChange={e => {
              const file = e.target.files[0]
              if (file) {
                const fileName = file.name.toLowerCase()
                if (fileName.endsWith(".html")) {
                  setHtmlCertificateFilename(e.target.files[0].name)
                  setIsHtmlFileSelected(true)
                  setHtmlCertificateSuccess(null)
                  setHelperText("")
                } else {
                  htmlCertificateFileInput.current.value = null
                  setIsHtmlFileSelected(false)
                  setHelperText("Некорректное расширение файла. Выберите файл с расширением .html")
                }
              }
            }}
          />
        </Button>
        {isHtmlCertificateLoading ? (
          <CircularProgress size={26} />
        ) : (
          <Button className={classes.checkButton} onClick={uploadCertificateTemplate} disabled={!isHtmlFileSelected}>
            Отправить
          </Button>
        )}
        <Typography className={classes.textFileName}>{htmlCertificateFilename}</Typography>
      </div>
      <Typography
        className={classes.textMess}
        style={{
          color: helperText
            ? "#d32f2f"
            : htmlCertificateSuccess
              ? "#36d136"
              : "inherit",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Roboto",
          textAlign: "left",
          margin: "8px 0 0 20px",
          lineHeight: "24px",
        }}
      >
        {helperText
          ? helperText
          : htmlCertificateSuccess === null
            ? ""
            : htmlCertificateSuccess
              ? "Отправлено"
              : "Ошибка при отправке"}
      </Typography>
    </div>
  )
}
