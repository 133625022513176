import { makeStyles } from "@mui/styles"
import { styled } from "@mui/material/styles"

export default makeStyles(theme => ({
  mainModule: {
    paddingLeft: "25vw",
    margin: 0,
    position: "relative",
    top: 100,
  },
}))

export const ModuleMainContainer = styled("div")(({ theme }) => ({
  paddingLeft: "25vw",
  margin: 0,
  position: "relative",
  top: 100,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "5vw",
    width: "100%",
  },
}))
