import React, { useEffect, useState } from "react"
import { EventItem } from "entities/Course"
import { Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"
// @ts-ignore
import Cookies from "js-cookie"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import * as Styled from "./Organizations.styles"
import { useTheme } from "shared/context"
import { Modal } from "shared/ui"
import { CreateCourse } from "features"
import { WebinarCard } from "entities/Webinar"
import { KruzhokCard } from "entities/Kruzhok/ui/KruzhokCard"

axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")

export const Events = () => {
  const theme = useTheme()
  const organization = useSelector(state => state.organization.currentOrganization)
  const [activeRows, setActiveRows] = useState([])
  const [futureRows, setFutureRows] = useState([])
  const [pastRows, setPastRows] = useState([])
  const [isOpenCreateEvent, setIsOpenCreateEvent] = useState(false)

  useEffect(() => {
    if (!organization) return
    let now = new Date()
    axios.get(`/organizations/${organization?.id}/courses/`).then(res => {
      let courses = res.data
      courses.forEach(e => {
        if (new Date(e.start) <= now && now <= new Date(e.finish)) setActiveRows(prev => [...prev, e])
        if (new Date(e.start) > now) setFutureRows(prev => [...prev, e])
        if (new Date(e.finish) < now) setPastRows(prev => [...prev, e])
      })
    })
  }, [])

  // ToDo потом поменять поведение кнопки
  function handleAddEventClick() {
    window.location.href = "/admin/course/"
  }

  const handleCreateEvent = () => {
    setIsOpenCreateEvent(prev => !prev)
  }

  return (
    <Styled.EventsMainContainer>
      <Modal isOpen={isOpenCreateEvent} onClose={handleCreateEvent} title={"Создать мероприятие"}>
        <CreateCourse />
      </Modal>
      <Styled.CreateEventButton onClick={handleCreateEvent}>
        <Styled.IconAdd />
        Создать событие
      </Styled.CreateEventButton>
      <Typography sx={{ marginTop: theme.spacing(16), marginBottom: theme.spacing(10) }} variant={"h3"}>
        Активные события
      </Typography>
      <CoursesContainer courses={activeRows} />
      <Typography sx={{ marginTop: theme.spacing(10), marginBottom: theme.spacing(10) }} variant={"h3"}>
        Запланированные
      </Typography>
      <CoursesContainer courses={futureRows} />
      <Typography sx={{ marginTop: theme.spacing(10), marginBottom: theme.spacing(10) }} variant={"h3"}>
        Прошедшие
      </Typography>
      <CoursesContainer courses={pastRows} />
    </Styled.EventsMainContainer>
  )
}

const CoursesContainer = ({ courses }) => {
  return (
    <>
      {courses
        .filter(c => c.type === "event")
        .map(event => (
          <div>
            <EventItem key={event.id} post={event} />
          </div>
        ))}
      <Grid container={true} spacing={6} sx={{ width: "100%" }}>
        {courses
          .filter(c => c.type === "webinar")
          .map(c => (
            <WebinarCard key={c.id} webinar={c} />
          ))}
      </Grid>
      <Grid container={true} spacing={6} sx={{ width: "100%" }}>
        {courses
          .filter(c => c.type === "kruzhok")
          .map(c => (
            //@ts-ignore
            <KruzhokCard key={c.id} kruzhok={{ ...c, template_props: JSON.parse(c.template_props) }} />
          ))}
      </Grid>
    </>
  )
}
