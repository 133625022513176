import { AssignmentFiltersSection, AssignmentExport, EditStudentProgress, AssignmentTable } from "widgets"
import styled from "@emotion/styled"
import useGetCourses from "entities/Assignment/model/useGetCourses"
import ExaminationModal from "widgets/Assignments/ExaminationModal"
import { useAppSelector } from "shared/hooks/useAppSelector"

const Assignments = ({ course }: { course?: number }) => {
  const { assignments } = useAppSelector(state => state.assignment)
  const { isLoaded } = assignments

  useGetCourses()

  return (
    <Container>
      <ExaminationModal />
      <AssignmentFiltersSection course_id={course || undefined} />
      {isLoaded && <AssignmentExport />}
      {isLoaded && <AssignmentTable />}
      <EditStudentProgress />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: min(95%, 1520px);
`

export { Assignments }
