import React, { useState, useEffect, useRef } from "react"
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from "@mui/material"
import { useHistory } from "react-router-dom"

import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import * as htmlToImage from "html-to-image"
import jsPDF from "jspdf"

import { Header, Footer } from "widgets"
import NoCertificate from "./NoCertificate"
import { styled } from "@mui/material/styles"
import { Button, Dropdown } from "../../shared/ui"

const downloadOptions = {
  pdf: "pdf",
  png: "png",
}

const Certificate = props => {
  const courseName = props.match.params.name

  const [courseId, setCourseId] = useState(null)
  const [downloadType, setDownloadType] = useState("pdf")
  const [certificate, setCertificate] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    axios.get(`/content/courses/`, { params: { slug: courseName } }).then(r => {
      let courseId = r.data[0].id
      setCourseId(courseId)
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios
        .post(`/content/certificates/${courseId}/create-certificate/`)
        .then(res => {
          setCertificate({ ...res.data })
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [courseName])

  const downloadCertificate = () => {
    if (downloadType === "pdf") window.open(certificate.file)
    else window.open(certificate.image)
  }

  const handleChangeFormat = e => {
    setDownloadType(e.target.value)
  }

  if (isLoading)
    return (
      <CertificateMainContainer>
        <CircularProgress />
      </CertificateMainContainer>
    )

  if ((!isLoading && !certificate) || !certificate.image) return <NoCertificate />

  return (
    <div>
      <Header variant={"shadow"} />
      <CertificateMainContainer>
        <div style={{ display: "flex", gap: 20 }}>
          <Button variant="contained" color="primary" onClick={downloadCertificate}>
            Скачать
          </Button>
        </div>
        <div>{!!certificate?.image && <img src={certificate.image} alt={"Certificate"} />}</div>
      </CertificateMainContainer>
      <Footer />
    </div>
  )
}

const CertificateMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(25),
  marginBottom: theme.spacing(25),
}))

export default Certificate
