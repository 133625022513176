import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import useStyles from "./styles"
import { useTheme } from "../../shared/context"

export function MultipartContentBlock(props) {
  var classes = useStyles()
  const theme = useTheme()
  const [content, setContent] = useState("")
  const [presets, setPresets] = useState("")
  const [width, setWidth] = useState("65vw")

  useEffect(() => {
    let preset = JSON.parse(props.block.props)
    setContent(preset.content)
    setPresets(preset)
    if (!!preset.width) {
      setWidth(parseInt(preset.width, 10))
    } else {
      setWidth("65vw")
    }
  }, [props.block.id])

  if (!content) return <></>

  return (
    <div
      style={{
        marginLeft: "5vw",
        marginRight: "5vw",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          width: width,
          [theme.breakpoints.down("sm")]: {
            width: "85vw !important",
          },
        }}
        align="left"
      >
        <div className={classes.contentText} dangerouslySetInnerHTML={{ __html: content }}></div>
      </Typography>
    </div>
  )
}
