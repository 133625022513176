import styled from "@emotion/styled"
import { RangesType, StatisticsVariants } from "entities/Organization/model/StatisticsContext.types"
import { ChartPie } from "entities/Organization/ui/Statistics/ChartPie"
import { ChartSunburst } from "entities/Organization/ui/Statistics/ChartSunburst"
import { Legend, ITabOption } from "entities/Organization/ui/Statistics/Legend"
import React, { createContext, Dispatch, SetStateAction, useState } from "react"
import { IEvent } from "entities/Organization/model/Organization.types"
import { RequestsAndStatusesLegend } from "entities/Organization/ui/Statistics/RequestsAndStatusesLegend"
import {
  RequestsAndStatusesCharts,
  RequestsAndStatusesChartType,
} from "entities/Organization/ui/Statistics/RequestsAndStatusesCharts"
import { DateRange } from "./ui/EventDateSelector"

interface IProps {
  type: StatisticsVariants
  tabOptions: ITabOption[]
  isLoading?: boolean
  event?: IEvent
  rangeType?: RangesType | undefined
  statisticsDisplayedDate?: DateRange | undefined
}

const requestsOptions = [{ id: 0, value: "general", label: "Общая информация" }]
const statusesOptions = [{ id: 1, value: "general", label: "Общая информация" }]
const surveyOptions = [{ id: 2, value: "general", label: "Общая информация" }]
const personalOptions = [...surveyOptions, { id: 3, value: "activity", label: "Успеваемость" }]

const pickInfoOptions = (type: StatisticsVariants) => {
  if (type === "requests") return requestsOptions
  if (type === "statuses") return statusesOptions
  if (type === "survey") return surveyOptions
  if (type === "personal") return personalOptions
  return []
}

const pickChart = (
  type: StatisticsVariants,
  infoType: {
    id: number
    value: string
    label: string
  },
  rangeType: RangesType | undefined,
  statisticsDisplayedDate: DateRange | undefined
) => {
  if (type === "requests")
    return (
      <RequestsAndStatusesCharts rangeType={rangeType} type={type} statisticsDisplayedDate={statisticsDisplayedDate} />
    )
  if (type === "statuses")
    return (
      <RequestsAndStatusesCharts rangeType={rangeType} type={type} statisticsDisplayedDate={statisticsDisplayedDate} />
    )
  if (type === "survey") return <ChartPie type={type} infoType={infoType.value} />
  if (type === "personal" && infoType?.value === "general") return <ChartPie type={type} infoType={infoType.value} />
  if (type === "personal" && infoType?.value === "activity")
    return <ChartSunburst type={type} infoType={infoType.value} />
  return <></>
}

interface ChartsContextType {
  chartsContextValue: RequestsAndStatusesChartType
  setChartsContextValue: Dispatch<SetStateAction<RequestsAndStatusesChartType>>
}
export const ChartsContext = createContext<ChartsContextType | undefined>(undefined)

const SummaryStatistics = ({ type, tabOptions, isLoading, event, rangeType, statisticsDisplayedDate }: IProps) => {
  const [infoType, setInfoType] = useState(pickInfoOptions(type)[0])
  const [chartsContextValue, setChartsContextValue] = useState<RequestsAndStatusesChartType>("scatter")
  return (
    <ChartsContext.Provider value={{ chartsContextValue, setChartsContextValue }}>
      <Container>
        {pickChart(type, infoType, rangeType, statisticsDisplayedDate)}
        {type === "requests" || type === "statuses" ? (
          <RequestsAndStatusesLegend
            infoType={infoType}
            setInfoType={setInfoType}
            type={type}
            tabOptions={tabOptions}
            infoOptions={pickInfoOptions(type)}
            isLoading={isLoading}
            event={event}
            rangeType={rangeType}
            statisticsDisplayedDate={statisticsDisplayedDate}
          />
        ) : (
          <Legend
            infoType={infoType}
            setInfoType={setInfoType}
            type={type}
            tabOptions={tabOptions}
            infoOptions={pickInfoOptions(type)}
          />
        )}
      </Container>
    </ChartsContext.Provider>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: 0 -32px;
`

export { SummaryStatistics }
