import React, { useMemo } from "react"
import { styled } from "@mui/material/styles"

import {
  Avatar,
  Button,
  Box,
  Link,
  Grid,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  CardActionArea,
  LinearProgress,
  Typography,
} from "@mui/material"
import { AvatarGroup as MuiAvatarGroup } from "@mui/material"
import { spacing } from "@mui/system"
import { useTheme } from "shared/context"
import useStyles from "./styles"

import { Card } from "shared/ui"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"

import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded"
import { useSelector } from "react-redux"

const CardContent = styled(MuiCardContent)`
  padding: 0px;
  padding-top: 16px;
`

const CardMedia = styled(MuiCardMedia)`
  height: 120px;
  width: 120px;
`

export function StageItem(props) {
  const image = props.image
  const title = props.title
  const subtitle = props.description
  const chip = props.chip
  const n_users = props.participants_num
  const team = useSelector(state => state.team.currentTeam)

  const classes = useStyles()
  const theme = useTheme()

  const AvatarGroup = styled(MuiAvatarGroup)`
    margin-left: ${theme.spacing(2)};
  `

  const bottomColor = "#858585"
  const today = useMemo(() => {
    return new Date()
  }, [props])

  function getStyles() {
    let styles = { height: "100%", width: "100%" }
    if (isAccessible()) {
      styles.opacity = 1.0
    } else {
      styles.opacity = 0.5
    }
    if (props.content_type === "content") {
      styles.backgroundColor = "#FFFFFF"
    } else if (props.content_type === "event") {
      styles.background = "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)"
    } else if (props.content_type === "online") {
      styles.background = "linear-gradient(90deg, #2E72EF 0%, #438BFF 100%)"
    }
    return styles
  }

  function isAccessible() {
    if (
      new Date(props.finish).setHours(23, 59, 59) <= today &&
      new Date(props.finish) > new Date(props.start) &&
      (props.status === "mentor" || props.status === "in_progress")
    ) {
      return false
    } else if (!props.status) {
      return props.is_active
    } else if (!props.start) {
      return props.is_active || props.status === "teacher" || props.status === "moderator"
    }
    return (
      (props.is_active && new Date(props.start) <= today) || props.status === "teacher" || props.status === "moderator"
    )
  }

  function stringifyDates() {
    if (!props.start || !props.finish) {
      return "Без ограничений по времени"
    }
    return props.stringifyDates(props.start, props.finish)
  }

  return (
    <Card style={getStyles()}>
      <CardActionArea
        component={Link}
        href={!!team ? `/module/${props.id}/${team.id}` : `/module/${props.id}`}
        disabled={!isAccessible()}
        style={{ height: "100%", width: "100%" }}
      >
        <div style={{ height: "80%", padding: 24 }}>
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div style={{ display: "flex", gap: 20 }}>{chip}</div>
              <div className={classes.circle} style={{ color: bottomColor, border: `1px solid ${bottomColor}` }}>
                <Typography variant={"caption"}>{props.index}</Typography>
              </div>
            </div>
          </CardContent>
          <Grid
            container
            spacing={0}
            sx={{ [theme.breakpoints.down("xs")]: { display: "flex", flexDirection: "column" } }}
          >
            <Grid item xs={9}>
              <CardContent>
                <Typography variant="h4">{title}</Typography>
              </CardContent>
            </Grid>
            <Grid item xs={3}>
              {image ? <CardMedia image={image} /> : null}
            </Grid>
          </Grid>
          <CardContent>
            <Typography mb={4} fontSize={"small"} fontWeight={"small"} variant={"body2"}>
              <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
            </Typography>
          </CardContent>
        </div>
        <CardActions
          style={{
            justifyContent: "space-between",
            borderTop: `1px solid #E0E0E0`,
            height: "20%",
            padding: 24,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 8 }}>
            <AccountCircleRoundedIcon style={{ color: bottomColor }} />
            <Typography variant={"caption"} style={{ color: bottomColor }}>
              {n_users}
            </Typography>
            <CalendarMonthIcon style={{ color: bottomColor }} />
            <Typography variant={"caption"} style={{ color: bottomColor }}>
              {stringifyDates()}
            </Typography>
          </div>
          <AvatarGroup max={3}>
            {props.affiliations_info && props.affiliations_info.map(e => <Avatar alt="Logo" src={e.icon_logo} />)}
          </AvatarGroup>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}
