import React, { useState } from "react"
import useStyle from "./styles"
import { Typography } from "@mui/material"
import { Button } from "../../../shared/ui"

const Advantage = ({ e }) => {
  const classes = useStyle()
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={classes.advantageContainer}
      onMouseUp={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img src={e.Image?.url} style={{}} className={classes.imageAdvantage} />
      <Typography variant={"h3"} color={e.H3?.color}>
        {e.H3?.text}
      </Typography>
      <Typography color={e.BaseText?.color}>{e.BaseText?.text}</Typography>
      {!!e.Button && (
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => !!e.Button.href && window.open(e.Button.href, "_blank")}
          sx={{ backgroundColor: e.Button.backgroundColor, color: e.Button.Color, width: "fit-content" }}
        >
          {e.Button.text || "Призыв к действию"}
        </Button>
      )}
    </div>
  )
}

export default Advantage
