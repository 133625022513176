import { styled } from "@mui/material/styles"

export const ControlTrajectoryContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(5),
  marginBottom: theme.spacing(17),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(4),
    width: "100%",
  },
}))

export const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  flexGrow: 1,
  gap: 31,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "40%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))

export const StyledTabButton = {
  color: "#FFFFFF",
  opacity: "1.0",
  marginTop: "30px",
  width: 40,
  height: 40,
  backgroundColor: "#1565C0",
  borderRadius: "8px",
  transition: "opacity 0.4s",
  "&:hover": {
    opacity: "0.7",
  },

  "&.Mui-disabled": {
    opacity: 0,
  },
}
