import React from "react"
import * as Styled from "./SingleAdvantage.styles"
import { Typography } from "@mui/material"
import { useTheme } from "../../../shared/context"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  e: {
    index: number
    block1: {
      text: string
      color: string
      backgroundColor: string
    }
    block2: {
      text: string
      color: string
      backgroundColor: string
    }
  }
}

export const SingleAdvantage: React.FC<IProps> = ({ ...props }) => {
  const theme = useTheme()
  return (
    <Styled.MainContainer>
      {!!props.e.block1 && (
        <Styled.BlockContainer
          sx={{
            background: props.e.block1.backgroundColor,
            justifyContent: "flex-end",
            paddingRight: "96px",
            [theme.breakpoints.down("md")]: { justifyContent: "center" },
          }}
        >
          <Typography
            sx={{ width: "50%", color: props.e.block1.color, [theme.breakpoints.down("sm")]: { width: "100%" } }}
            variant={"h1"}
          >
            {props.e.block1.text}
          </Typography>
        </Styled.BlockContainer>
      )}
      {!!props.e.block2 && (
        <Styled.BlockContainer
          sx={{
            justifyContent: "flex-start",
            background: props.e.block2.backgroundColor,
            paddingLeft: "96px",
            [theme.breakpoints.down("md")]: { justifyContent: "center" },
          }}
        >
          <Typography
            sx={{ width: "50%", color: props.e.block2.color, [theme.breakpoints.down("sm")]: { width: "100%" } }}
            variant={"body1"}
            fontWeight={"medium"}
            fontSize={"large"}
          >
            {props.e.block2.text}
          </Typography>
        </Styled.BlockContainer>
      )}
    </Styled.MainContainer>
  )
}
